import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Wrapper } from '~/components/_helpers';

import manInTheCar from './assets/manInTheCar.svg';
import medalEconomy from './assets/medalEconomy.svg';
import odometer from './assets/odometer.svg';
import policy from './assets/policy.svg';
import coverageAndServices from './assets/coverageAndServices.svg';
import useWindowSize from '@rooks/use-window-size';

import * as S from './styles';

import '../../../../node_modules/swiper/swiper.scss';

export default function About() {
  const size = useWindowSize();

  const manInTheCarIcon = manInTheCar;
  const medalEconomyIcon = medalEconomy;
  const odometerIcon = odometer;
  const policyIcon = policy;
  const coverageAndServicesIcon = coverageAndServices;

  const iconsAboutCompany = [
    {
      src: manInTheCarIcon,
      alt: 'Ícone de um menino dirigindo um carro.',
      paragraph: 'Feito para ',
      strong: 'quem dirige pouco, mas não abre mão de proteção',
    },
    {
      src: medalEconomyIcon,
      alt: 'Ícone de uma medalha com um símbolo de porcentagem.',
      paragraph: 'Economia de até ',
      strong: '50% por mês',
    },
    {
      src: odometerIcon,
      alt: 'Ícone de um odômetro.',
      paragraph: 'Kms são capturados através de foto do odômetro todo mês ',
      strong: 'via aplicativo.',
    },
    {
      src: policyIcon,
      alt: 'Ícone de uma apólice.',
      paragraph: 'Apólices ',
      strong: 'mensais e que se renovam automaticamente',
    },
    {
      src: coverageAndServicesIcon,
      alt: 'Ícone de um contrato com um brasão na frente.',
      paragraph: '',
      strong: 'Coberturas e serviços completos',
    },
  ];

  return (
    <Wrapper
      color='#fff'
      marginTop='2.3375rem'
      mobile_mt='3rem'
      width={size.innerWidth}
    >
      <S.Container>
        <S.Title>O que é o AUTO Controle</S.Title>
        <S.Subtitle>
          O seguro AUTO Controle, é o{' '}
          <strong>seguro por quilômetro</strong> da Yelum Seguradora, em que o
          cliente <strong>paga mensalmente</strong>,
          <strong> baseado no quanto dirige</strong>.
        </S.Subtitle>
        <S.IconsContainer>
          <S.IconsBorderTop />

          <S.IconsContent>
            {iconsAboutCompany.map(
              ({ src, alt, paragraph, strong }) =>
                size.innerWidth >= 768 && (
                  <S.Icon key={alt}>
                    <img src={src} alt={alt} />
                    <p>
                      {paragraph}
                      <strong>{strong}</strong>
                    </p>
                  </S.Icon>
                )
            )}

            {size.innerWidth < 768 && (
              <Swiper slidesPerView={2.4} navigation>
                {iconsAboutCompany.map(({ src, alt, paragraph, strong }) => (
                  <SwiperSlide key={alt}>
                    <S.Icon>
                      <img src={src} alt={alt} />
                      <p>
                        {paragraph}
                        <strong>{strong}</strong>
                      </p>
                    </S.Icon>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </S.IconsContent>
          <S.IconsBorderBottom />
        </S.IconsContainer>
      </S.Container>
    </Wrapper>
  );
}
