import React, { useState, useEffect } from 'react';

import car from './assets/car.svg';
import arrow from './assets/arrow.png';
import x from './assets/x.svg';
import piggyBanking from './assets/piggyBanking.svg';
import e from './assets/e.svg';
import icon1200km from './assets/1200km.svg';

import * as S from './styles';

import constant from './constant';
import { formatPrice } from '~/utils/helpers';

import { getCalculator } from '~/api/modules/calculator';

export default function TimeLine({
  values,
  code,
  monthlyPayment,
  kmDay,
  discountedMonth,
}) {
  const [marks, setMarks] = useState([]);
  const [calculatorValue, setCalculatorValue] = useState();

  useEffect(() => {
    if (!values) return;

    if (code) {
      async function getCalculatorValue() {
        const {
          request: { liberty_value },
        } = (await getCalculator(code)).data;

        setCalculatorValue(liberty_value);
      }
      getCalculatorValue();
    }

    const temp = [];
    values.forEach((el, i) => {
      if (el.km_media_month === 1200) {
        el.km_media_month = '+ 1200';
      }

      temp.push({
        ...constant[i],
        ...el,
      });
    });

    setMarks(temp);
  }, [values, code]);

  return (
    <S.Wrapper code={code}>
      <S.Container>
        {marks.map((step, i) => (
          <S.Step key={`step-${i}`} selected={step.selected}>
            {step.status && (
              <S.Status
                selected={step.selected}
                src={step.status.src}
                alt={step.status.alt}
              />
            )}
            {step.selected && (
              <S.Car selected={step.selected} status={step.status}>
                {i > 0 && (
                  <button
                    className='prev'
                    onClick={() => {
                      const temp = [];
                      marks.forEach(el => {
                        temp.push({
                          ...el,
                          selected: false,
                        });
                      });
                      temp[i - 1] = {
                        ...temp[i - 1],
                        selected: true,
                      };
                      setMarks(temp);
                    }}
                  >
                    <img src={arrow} alt='seta para voltar' />
                  </button>
                )}
                <img src={car} alt='carro' />
                {i < marks.length - 1 && (
                  <button
                    className='next'
                    onClick={() => {
                      const temp = [];
                      marks.forEach(el => {
                        temp.push({
                          ...el,
                          selected: false,
                        });
                      });
                      temp[i + 1] = {
                        ...temp[i + 1],
                        selected: true,
                      };
                      setMarks(temp);
                    }}
                  >
                    <p>{window.innerWidth > 768 && 'Arraste o carro'}</p>
                    <img src={arrow} alt='seta para avançar' />
                  </button>
                )}
              </S.Car>
            )}
            <S.Mark selected={step.selected} status={step.status}>
              <button
                onClick={() => {
                  const temp = [];
                  marks.forEach(el => {
                    temp.push({
                      ...el,
                      selected: el.km_media_month === step.km_media_month,
                    });
                  });
                  setMarks(temp);
                }}
              >
                <h2>
                  {step.km_media_month} km {step.selected && 'por mês'}
                  {step.selected && (
                    <small>
                      ou <strong>{step.km_media_day} km</strong> por dia
                    </small>
                  )}
                </h2>
                {!code ? (
                  step.selected && (
                    <>
                      <S.TooltipTop
                        first={i > 0 ? false : true}
                        limit={step.limit}
                      >
                        {i < marks.length - 1 && (
                          <h3>
                            Economia de:
                            <small>Em relação ao Auto Perfil</small>
                            <span>{step.economy_month}%</span>
                          </h3>
                        )}
                        {i >= marks.length - 1 && (
                          <h4>
                            O cliente será cobrado por, no máximo,
                            {' ' + step.km_media_month}km por mês
                          </h4>
                        )}
                        <p>
                          Valor cobrado no mês:
                          <span>{formatPrice(step.value_payment)}</span>
                        </p>
                        <p>
                          Valor da apólice anual:
                          <span>{formatPrice(step.annual_value)}</span>
                        </p>
                        <p>
                          Economia anual:
                          <span>{formatPrice(step.annual_economy || 0)}</span>
                        </p>
                      </S.TooltipTop>
                    </>
                  )
                ) : (
                  <>
                    {step.selected && (
                      <S.TooltipBottom
                        first={i > 0 ? false : true}
                        limit={step.limit}
                      >
                        <S.TooltipBottomTitle>
                          Seguro tradicional
                        </S.TooltipBottomTitle>
                        <S.TooltipBottomPriceForMonth>
                          {calculatorValue
                            ? formatPrice(String(calculatorValue) / 12) + `/mês`
                            : ''}
                        </S.TooltipBottomPriceForMonth>
                        <S.MultiplicationIcon
                          src={x}
                          alt='Ícone de um símbolo de multiplicação.'
                        />
                        <S.AutoControle>
                          AUTO Controle
                        </S.AutoControle>
                        <S.AutoControlePrice>
                          {formatPrice(step.value_payment)}
                        </S.AutoControlePrice>
                        <S.Divider />
                        <S.TooltipBottomEconomyContainer>
                          <img src={piggyBanking} alt='Ícone de um cofrinho.' />
                          <S.TooltipBottomEconomyContent>
                            <S.TooltipBottomEconomyTitle>
                              Economize no mês:
                            </S.TooltipBottomEconomyTitle>
                            <S.TooltipBottomEconomyValue>
                              {step.economy_month}%
                            </S.TooltipBottomEconomyValue>
                          </S.TooltipBottomEconomyContent>
                        </S.TooltipBottomEconomyContainer>
                        <S.TooltipBottomEconomyExclamation>
                          * Em comparação ao seguro Auto Perfil
                        </S.TooltipBottomEconomyExclamation>
                      </S.TooltipBottom>
                    )}
                  </>
                )}
              </button>
            </S.Mark>
          </S.Step>
        ))}
      </S.Container>
      <S.Container>
        {code !== null && (
          <S.CardContainer>
            <S.Card>
              <S.CardTitleContainer>
                <img src={e} alt='Ícone de uma placa de estacione.' />
                <h3>E SE EU NÃO DIRIGIR ?</h3>
              </S.CardTitleContainer>
              <S.CardDescriptionContainer>
                <p>
                  Se você não dirigir durante o mês inteiro, pagará apenas a
                  taxa fixa mensal, que garante sua proteção mesmo quando você
                  não dirige.
                </p>
              </S.CardDescriptionContainer>
              <S.CardValuesContainer>
                <S.CardValuesTop>
                  <strong>{formatPrice(monthlyPayment)}</strong>
                  <span>+</span>
                  <strong>0 km</strong>
                  <span>x</span>
                  <strong>{formatPrice(kmDay)}</strong>
                  <span>=</span>
                </S.CardValuesTop>
                <S.CardValuesBottom>
                  <strong>{formatPrice(values[0].value_payment)}</strong>
                </S.CardValuesBottom>
              </S.CardValuesContainer>
            </S.Card>
            <S.Card>
              <S.CardTitleContainer>
                <img
                  src={icon1200km}
                  alt='Ícone de uma placa de mil e duzentos quilometros.'
                />
                <h3>EXISTE UM LIMITE DE KM ?</h3>
              </S.CardTitleContainer>
              <S.CardDescriptionContainer>
                <p>
                  Não! O valor máximo que você pagará é o equivalente a 1.200
                  km. Se dirigir, por exemplo, 1.429 km, cobraremos apenas 1.200
                  km
                </p>
              </S.CardDescriptionContainer>
              <S.CardValuesContainer>
                <S.CardValuesTop>
                  <strong>{formatPrice(monthlyPayment)}</strong>
                  <span>+</span>
                  <strong>1200 km</strong>
                  <span>x</span>
                  <strong>{formatPrice(kmDay)}</strong>
                  <span>=</span>
                </S.CardValuesTop>
                <S.CardValuesBottom>
                  <strong>{formatPrice(values[8].value_payment)}</strong>
                </S.CardValuesBottom>
              </S.CardValuesContainer>
            </S.Card>
            <S.Card>
              <S.CardTitleContainer>
                <h3>EM UM ANO…</h3>
              </S.CardTitleContainer>
              <S.CardDescriptionContainer>
                {marks.map(
                  (step, index) =>
                    step.selected && (
                      <p key={index}>
                        Se você dirigir em média{' '}
                        <strong className='km-media-month-strong'>
                          {step.km_media_month} km /mês
                        </strong>
                        , em 1 ano com o AUTO Controle, você pagará cerca de:
                      </p>
                    )
                )}
              </S.CardDescriptionContainer>

              <S.CardValuesContainer>
                <S.CardValuesTop className='last-card-values-top'>
                  <strong>2</strong>
                  <span>x</span>
                  <strong className='discounted-month'>
                    {formatPrice(discountedMonth)}
                  </strong>
                  <span>+</span>
                  <strong>10</strong>
                  <span>x</span>

                  <strong>
                    (<strong>{formatPrice(monthlyPayment)}</strong>
                    <span>+</span>
                    <strong>
                      {marks.map(step => step.selected && step.km_media_month)}
                      km
                    </strong>
                    <span>x</span>
                    <strong>{formatPrice(kmDay)}</strong>)
                  </strong>
                  <span>=</span>
                </S.CardValuesTop>

                <S.CardValuesBottom>
                  <strong>
                    {marks.map(
                      step => step.selected && formatPrice(step.annual_value)
                    )}
                  </strong>
                </S.CardValuesBottom>
              </S.CardValuesContainer>
            </S.Card>
          </S.CardContainer>
        )}
      </S.Container>
    </S.Wrapper>
  );
}
