import React from 'react';

import useWindowSize from '@rooks/use-window-size';

import { Wrapper } from '~/components/_helpers';

import backgroundAutoControle from './assets/backgroundAutoControle.svg';
import arrowRightYellow from './assets/arrowRightYellow.svg';
import arrowRightYellowMobile from './assets/arrowRightMobile.svg';
import car from './assets/car.svg';
import carMobile from './assets/carMobile.svg';

import * as S from './styles';

export default function Banner() {
  const size = useWindowSize();

  return (
    <S.MainSection>
      <S.Background>
        <img
          src={backgroundAutoControle}
          alt='Imagem de fundo na cor azul e com pequenas escritas em branco dizendo: auto controle.'
        />
      </S.Background>
      <S.MainSectionWrapper>
        <Wrapper color='transparent' height='100%' width={size.innerWidth}>
          <S.ArrowRightContainer>
            <S.ArrowRightTitle>
              Com o AUTO Controle quanto menos você anda, menos você
              paga
            </S.ArrowRightTitle>
            <S.ArrowRightSubtitle>
              O seguro por km da Yelum Seguradora 
            </S.ArrowRightSubtitle>
            {size.innerWidth >= 768 ? (
              <img
                src={arrowRightYellow}
                alt='Imagem de uma seta amarela apontando para a direita.'
              />
            ) : (
              <img
                src={arrowRightYellowMobile}
                alt='Imagem de uma seta amarela apontando para a direita.'
              />
            )}
          </S.ArrowRightContainer>
          <S.CarContainer>
            {size.innerWidth >= 768 ? (
              <img
                src={car}
                alt='Imagem de um carro azul com um relógio ao fundo.'
              />
            ) : (
              <img
                src={carMobile}
                alt='Imagem de um carro azul com um relógio ao fundo.'
              />
            )}
          </S.CarContainer>
        </Wrapper>
      </S.MainSectionWrapper>
    </S.MainSection>
  );
}
