import React, { useState } from 'react';
import { Wrapper } from '~/components/_helpers';
import { WrapperSteps, Title, Container, Content } from './styles';

export default function Steps() {
  const [steps, setSteps] = useState([
    {
      title: 'Contratação e Transmissão',
      description:
        'Corretor realiza a venda e emite a apólice de seguros através do “Meu Cotador”',
      selected: true,
      id: 'passo-contratacao',
    },
    {
      title: 'Pagamento',
      description:
        'Pagamento via cartão de crédito ou débito em 3 dias úteis após a contratação',
      selected: false,
      id: 'passo-pagamento',
    },
    {
      title: 'Vistoria',
      description:
        'A vistoria será realizada em até 7 úteis após a contratação',
      selected: false,
      id: 'passo-vistoria',
    },
    {
      title: 'Instalação do aplicativo',
      description:
        'O cliente deve instalar o aplicativo AUTO Controle no celular e criar sua conta (em até 15 dias após o início da vigência)',
      selected: false,
      id: 'passo-instalacao',
    },
    {
      title: 'Tirar a foto do odômetro todos os meses',
      description:
        'Todo mês o cliente deverá tirar a foto do odômetro ou justificar em caso de impossibilidade, tudo através do app.',
      selected: false,
      id: 'passo-tirar-foto',
    },
    {
      title: 'Renovação automática',
      description:
        'Após o pagamento, a renovação do plano é feita automaticamente no final de cada mês',
      selected: false,
      id: 'passo-renovacao-automatica',
    },
  ]);
  return (
    <WrapperSteps>
      <Wrapper color='#f5f5f5' mobile_mt='0px'>
        <Title>Quais os próximos passos?</Title>
        <Container>
          <hr />

          {steps.map((step, i) => (
            <Content key={`step-${i}`} selected={step.selected} id={step.id}>
              <span
                onClick={() => {
                  const temp = [];
                  steps.forEach(el => {
                    temp.push({
                      ...el,
                      selected: el.title === step.title,
                    });
                  });
                  setSteps(temp);
                }}
              >
                {i + 1}
              </span>

              <p>{step.title}</p>

              {step.selected && <div>{step.description}</div>}
            </Content>
          ))}
        </Container>
      </Wrapper>
    </WrapperSteps>
  );
}
