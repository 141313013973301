import React from 'react';
import RModal from 'react-modal';

import {
  modalStyles,
  ModalHeader,
  ModalTitle,
  ModalClose
} from './styles';

import CloseIcon from './assets/close.svg';

RModal.setAppElement('#root');

export default function Modal(props) {
  const {
    children,
    title,
    isOpen,
    toggle,
    shouldCloseOnEsc = true,
    onRequestClose,
    style,
  } = props;

  const customStyles = {
    ...modalStyles,
    ...style,
    content: style?.content || modalStyles.content,
  };

  const closeModal = () => {
    toggle(false);
    onRequestClose && onRequestClose();
  };

  return (
    <RModal
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <ModalHeader>
        <ModalTitle>
          {title}
        </ModalTitle>
        <ModalClose
          src={CloseIcon}
          alt="Fechar"
          onClick={closeModal}
        />
      </ModalHeader>

      <div>{children}</div>
    </RModal>
  );
}
