import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 40px auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px auto;

  .Collapsible {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: #1a1446;
    cursor: pointer;
    width: 100%;

    .Collapsible__trigger {
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 500;
      background-color: #2C3A39;
      padding: 10px 10px 10px 25px;
    }

    .Collapsible__contentInner {
      padding: 20px 0 20px 100px;
    }

    p {
      cursor: auto;
      color: #2C3A39;
      padding-left: 20px;
      line-height: 1.5;
    }

    h1 {
      color: red;
    }

    a {
      color: #2C3A39;
      text-decoration: none;
    }
  }

  @media (max-width: 320px) {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 20px 0 20px 20px;
      }
    }
  }

  @media (max-width: 375px) {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 20px 0 20px 20px;
      }
    }
  }

  @media (max-width: 425px) {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 20px 0 20px 20px;
      }
    }
  }

  @media (max-width: 768px) {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 20px 0 20px 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    .Collapsible {
      .Collapsible__contentInner {
        padding: 20px 0 20px 20px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const Title = styled.div`
  display: flex;
  color: #2C3A39;

  img {
    margin-right: 15px;
  }

  span {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const SubLabel = styled.h4`
  font-size: 20px;
  color: #2C3A39;
  margin-left: 21px;
`;
