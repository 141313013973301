import React from 'react';
import { navigate } from '@reach/router';

import Questions from './Questions';

import { Wrapper, Button } from '~/components/_helpers';

import Woman from './assets/woman_faq.svg';

import { Container, Content, ContentImg } from './styles';

export const FAQ = () => {
  return (
    <Wrapper faq mobile_mt="0px">
      <Container>
        <Content size={0.4}>
          <ContentImg>
            <img src={Woman} alt="mulher com tablet nos braços" />
          </ContentImg>
        </Content>
        <Content questions size={0.6}>
          <h1>Perguntas frequentes</h1>
          <Questions />
          <Button theme="primary" onClick={() => navigate('/faq')}>
            Ver todas perguntas
          </Button>
        </Content>
      </Container>
    </Wrapper>
  );
};
