import styled from 'styled-components';
import { FlexContent } from '~/components/_helpers/basic-diagramation';

export const Content = styled(FlexContent)`
  @media(max-width: 576px) {
    margin-top: 82px;
  }

  h1 {
    color: #2C3A39;
    font-size: 40px;
    font-weight: 500;
    margin-top: 82px;
    margin-bottom: 36px
  }
`;
