import styled from 'styled-components';

import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 576px) {
    padding-top: 0;
  }

  @media(max-width: 1000px) {
    flex-direction: column;
    > div {
      flex: 1;
    }
  }
`;

export const Content = styled(FlexContent)`
  &:first-child {
    display: flex;
    justify-content: space-between;
  }

  &:last-child {
    padding: 0;
  }

  h1 {
    color: #06748c;
    font-size: 40px;
    font-weight: 500;
  }

  p {
    font-size: 20px;

    @media(max-width: 576px) {
      margin: 0;
    }
  }
`;

export const ContentImg = styled.div`
  height: 194px;
  display: flex;
  justify-content: center;
  margin: 0 2.875em 0 auto;

  @media(max-width: 576px) {
    display: none;
  }

  img {
    width: 200px;
    height: 370px;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    height: 15rem;
  }

  @media (max-width: 768px) {
    height: 20rem;
  }
`;

export const ContentTitle = styled.div`
  width: 284px;
  height: 194px;
  color: #2C3A39;

  @media(max-width: 576px) {
    height: auto;
    margin-bottom: 25px;
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
    line-height: 1.4;
    margin: 0;
    color: #2C3A39;

    @media(max-width: 576px) {
      text-align: center;
    }
  }
`;
