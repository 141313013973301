import React from 'react';

import { Wrapper } from '~/components/_helpers';
import * as S from './styles';

import logoAutoControle from './assets/logo-autocontrole.svg';

const HeaderTransparent = () => (
  <S.Header>
    <Wrapper color='transparent' mobile_mt='0'>
      <img
        src={logoAutoControle}        
      />
    </Wrapper>
  </S.Header>
);

export default HeaderTransparent;
