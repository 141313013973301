import styled from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1.75em;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #2C3A39;

  .Collapsible {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    font-size: 20px;
    color: #2C3A39;
    border-bottom: 1px solid #1a1446;
    cursor: pointer;
    width: 100%;

    .Collapsible__trigger {
      color: #2C3A39;
      font-size: 20px;
      font-weight: 500;
    }
    p {
      cursor: auto;
      strong {
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        text-align: left;
        color: #2C3A39;
      }
    }

    .Collapsible__contentInner {
      margin: 0 30px;
    }
  }
`;

export const Container = styled.div`
  display: flex;

  & + div {
    margin-top: 0.5em;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: 600px;
  height: 46px;
  border: none;
  padding: 0 1.53125em;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s;
  cursor: pointer;
  color: #2C3A39;

  @media(max-width: 576px) {
    width: 100%;
    height: auto;
    padding: 1.53125em;
  }

  & + button {
    margin-top: 0.5em;
  }

  &:hover {
    background-color: ${shade(0.05, '#ffffff')};
  }

  img {
    margin-right: 1.20625em;
  }

  a {
    font-size: 20px;
    text-decoration: none;
    color: #2C3A39;
  }
`;
