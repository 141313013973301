import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    text-transform: uppercase;
  }

  img {
    margin-right: 15px;
  }
`;
