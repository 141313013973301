import styled from 'styled-components';
import { shade } from 'polished';

import { ContainerFlex } from '~/components/_helpers/basic-diagramation';

export const Container = styled.div`
  display: flex;
  margin-top: 2.8125em;
  margin-bottom: 1.875em;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: ${({ values }) => values && '150px'};
  }
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerDataTitle = styled.h2`
  flex: 1;
  font-size: 20px;
  font-weight: normal;
  color: #2C3A39;
  padding-right: 5%;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }

  > span {
    width: 80px;
    height: 28px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #2C3A39;
  margin-top: 0;
  margin-bottom: 0.472em;

  @media (max-width: 1000px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
  color: #343741;
  margin-top: 0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const CalculatorContainer = styled(ContainerFlex)`
  min-width: 526px;
  min-height: 116px;
  display: flex;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  padding: 19px 30px 29px 25.5px;
  border-radius: 5px;
  margin-bottom: ${props => (props.values ? 0 : '100px')};
  margin-right: calc(85px - 16px);

  @media (max-width: 1149px) {
    min-width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
`;

export const Values = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${props => props.border && '0 30px 0 45px'};
  border-left: ${props => (props.border ? '1px solid #343741' : 'none')};
  margin-right: 33px;
  z-index: 2;
  color: #2C3A39;

  @media (max-width: 900px) {
    display: block;
    padding: ${props => props.border && '0 15px 0 45px'};
  }

  @media (max-width: 768px) {
    padding: ${props => props.border && '30px 15px 0 15px'};
    margin-right: 0;
  }

  & + div {
    margin-right: 0;
  }

  label {
    margin-bottom: 0;
  }

  h3 {
    font-size: 24px;
    width: 100%;
    margin-top: 0;

    small {
      margin-left: 5px;
      font-size: 16px;
      font-style: italic;
      color: #2C3A39;
      font-weight: normal;
    }
  }

  p {
    width: 100%;
    margin: 0;
  }
`;

export const Content = styled.div`
  flex: ${props => props.size};

  @media (max-width: 768px) {
    margin: 0;
  }

  img {
    margin-top: 3px;
  }

  button {
    width: 100%;
    margin-top: 2px;
    margin-bottom: ${props => (props.error ? '25px' : 0)};
    margin-left: 16px;

    @media (max-width: 900px) {
      margin: 0;
    }
  }
`;

export const Data = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;

  > span {
    width: 100%;
    display: flex;
    margin: 0 30px 5px 30px;
    text-align: center;

    strong {
      flex: 0.16;
      border-radius: 13px;
      font-size: 12px;
      color: #1a1446;
      background-color: rgba(255, 208, 0, 0.51);
      padding: 3px 10px;
      margin: 0;

      @media (max-width: 768px) {
        margin-top: 10px;
        flex: 0.5;
      }
    }
  }
`;

export const Info = styled.div`
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #1a1446;
  border-radius: 5px;
  text-align: center;
  padding: 0.5em 0;
  position: relative;

  &:nth-child(1) {
    width: 248px;
  }

  &:nth-child(3) {
    width: 15.5rem;
  }

  &:nth-child(5) {
    width: 36.875rem;
  }

  &:nth-child(1),
  &:nth-child(3) {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &:nth-child(5) {
    flex: 1;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  h6 {
    font-size: 20px;
    font-weight: 500;
    color: #2C3A39;
    margin-top: 0;
    margin-bottom: 0.4em;
  }

  strong {
    color: #2C3A39;
    font-size: 22px;
    margin-bottom: 0.36em;
  }

  span {
    max-width: 13.5rem;
    font-size: 16px;
    font-style: italic;
    color: #2C3A39;
  }

  .InfoBottom-first {
    width: 248px;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    > p {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .InfoBottom-second,
  .InfoBottom-third {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 37px;
    padding-right: 37px;
    position: absolute;

    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;
      top: 310px;
    }
  }

  .InfoBottom-third {
    justify-content: center;
    margin-left: calc(50% - 1px);
    padding: 13px 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-left: 37px;
      padding-right: 37px;
      padding: 20px 10px;
    }
  }
`;

export const InfoBottom = styled.div`
  height: 61px;
  background-color: #f5f5f5;
  border: 1px solid #1a1446;
  border-radius: 5px;
  padding: 13px 10px 8px;
  position: absolute;
  top: 112px;
  left: 0;
  right: 0;
  z-index: -1;

  @media (max-width: 768px) {
    z-index: 0;
  }

  > img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 8px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }

  > p {
    width: 226px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    color: #2C3A39;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }

    > strong {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.13;
      color: #2C3A39;
      margin-bottom: 0;

      @media (max-width: 768px) {
        margin-top: 5px;
      }
    }
  }
`;

export const ContainerContentInfo = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      max-width: 130px;
    }
  }
`;

export const ContainerContentInfoIcon = styled.button`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin: 0 1.625em;
  margin-top: 0.3125em;

  @media (max-width: 768px) {
    margin: 15px;
  }

  > img {
    width: 12px;
    height: 12px;
  }
`;

export const Discount = styled.small`
  color: #343741;
  font-size: 16px;
  font-weight: normal;

  @media (max-width: 1200px) {
    display: block;
  }

  @media (max-width: 900px) {
    width: auto;
    float: left;
    padding: 5px 5px 0 0;
  }
`;

export const Description = styled.p`
  padding: 0 40px;

  @media (max-width: 768px) {
    padding: 30px 0 0 15px;
  }
`;

export const ContainerArrow = styled.div`
  margin: 0 1em;

  &:nth-child(1),
  &:nth-child(3) {
    @media (max-width: 768px) {
      padding-top: 48px;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;

    img {
      transform: rotate(90deg);
    }
  }
`;

export const Policy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: ${props => props.mb && props.mb};
    margin-top: ${props => props.mt && props.mt};
  }

  a {
    text-decoration: none;
  }

  h1 {
    margin-top: 0;
  }

  button {
    width: 330px;
  }
`;

export const ButtonTips = styled.button`
  width: 444px;
  height: 174px;
  display: flex;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 0.875em 1.5625em 0 1.5625em;
  margin-top: 18px;
  margin-left: 4.375em;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${shade(0.05, '#ffffff')};
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  div:first-child {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.0625em;

    a {
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      margin-top: 0.5em;
      color: #2C3A39;

      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }

  div:last-child {
    margin-left: 2.0625em;

    > img {
      width: 129px;
      position: relative;
      top: 3px;
    }
  }
`;

export const ButtonTipsTitle = styled.h3`
  font-size: 20px;
  font-weight: 100;
  text-align: left;
  line-height: 1.4;
  color: #2C3A39;
  margin: 0;

  @media (max-width: 768px) {
    line-height: normal;
  }
`;

export const ContainerArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  position: relative;
  bottom: 42px;

  @media (max-width: 768px) {
    bottom: 7px;
  }
`;

export const ContainerSecondArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  position: relative;

  @media (max-width: 768px) {
    bottom: 30px;
  }
`;

export const ModalPDFContent = styled.div`
  padding: 21px 30px 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  align-items: center;

  justify-content: space-space-between;

  p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
    color: #333;
  }

  & > div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;

    button {
      background-color: #ffd000;
      border: 0;
      min-width: 180px;
      padding: 11px 0;
      font-size: 15px;
      color: #1a1446;
      border-radius: 5px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        filter: brightness(0.95);
      }
    }

    input {
      border: solid 1px ${props => (props.error ? 'red' : '#ffd000')};
      padding: 11px 10px;
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      font-size: 15px;
      color: #333;

      &::placeholder {
        color: #333;
      }
    }
  }
`;

export const ButtonSharingGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  div {
    flex: 1;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonDownloadPDF = styled.button`
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #2C3A39;
  border: solid 2px #2C3A39;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.2s;
  padding: 12px 35px;
  outline: none;
  cursor: pointer;
  z-index: 2;

  margin-left: 15px;
  width: 50%;

  &:hover {
    background-color: ${shade(0.1, '#2C3A39')};
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const ButtonSharingLink = styled.button`
  color: #2C3A39;
  background-color: #FFC900;
  border: solid 2px #FFC900;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.2s;
  padding: 12px 35px;
  outline: none;
  cursor: pointer;
  z-index: 2;

  width: 50%;

  &:hover {
    background-color: ${shade(0.1, '#FFC900')};
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const ModalSharingLinkOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`;

export const ModalSharingLink = styled.div`
  background-color: #ffffff;
  padding: 1.625em 1.90625em 2.6875em 1.90625em;
  border: solid 1px #707070;
  border-radius: 5px;
  position: relative;
`;

export const ModalSharingLinkHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.625em;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    background-color: #b2b2b2;
    position: absolute;
    top: 74px;
    left: 0;
    right: 0;
    opacity: 0.2;
  }

  > div {
    font-size: 18px;
    color: #1b1247;
  }

  > button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

export const ModalSharingBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalSharingBodyDescription = styled.div`
  max-width: 530px;

  p {
    width: 100%;
    font-size: 18px;
    color: #333333;
    line-height: 1.67;
  }
`;

export const ModalSharingBodyActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    flex: 1;
    height: 42px;
    font-size: 15px;
    padding-left: 13px;
    border: solid 1px #ffd000;
    border-radius: 5px;
    color: #333333;
  }

  button {
    width: 92px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #1a1446;
    background-color: #ffd000;
    margin-left: 5.5px;
    border: none;
    border-radius: 5px;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: ${shade(0.1, '#ffd000')};
    }
  }
`;

export const ModalSharingTooltip = styled.span`
  width: 110px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  top: 43px;
  transition: all 0.2s;
  cursor: normal;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    border-top-color: #ffffff;
    transform: rotate(180deg) translate(45%, 0);
  }

  p {
    font-size: 14px;
    color: #1b1247;
  }
`;

export const TimeLineTitle = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 90px;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    color: #2C3A39;
    margin-top: 43px;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      text-align: center;
    }

    > mark {
      background-color: #FFC900;
    }
  }

  margin-bottom: ${props => (props.values ? 0 : '100px')};
  margin-top: ${props => (!props.hasPadding ? 0 : '53px')};
`;

export const ContainerTitle = styled.div`
  color: #1a1446;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }

  h2 {
    font-size: 36px;
    font-weight: 300;
    text-align: left;
    margin-top: 42px;
    margin-bottom: 28px;

    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 0.625rem;
    }
  }

  h3 {
    display: block;
    font-size: 21px;
    font-weight: 300;
    margin-top: 42px;
    margin-bottom: 28px;
    color: #343741;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 1.9375rem;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 100;
    text-align: center;
    color: #343741;
    margin-top: 12.5px;
    margin-bottom: 40px;
  }
`;

export const CalculatorsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 116px;
  margin-top: 58px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const CalculatorKmContainer = styled.div`
  width: 303px;
  height: 100%;
  background-color: #F5F5F5;
  padding: 14px 41px 16.5px 24px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CalculatorKmTitleContainer = styled.div`
  width: 238px;
  position: relative;
`;

export const CalculatorKmTitle = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: -0.18px;
  color: #2C3A39;
  margin-top: 0;
  margin-bottom: 11.7px;
`;

export const CalculatorKmTooltipIconContainer = styled.span`
  position: absolute;
  top: 24px;
  left: 77px;
`;

export const CalculatorKmTooltipIcon = styled.img`
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CalculatorKmTooltipContent = styled.p`
  font-size: 14px;
  color: #2C3A39;
  margin: 0;
`;

export const CalculatorKmForm = styled.form`
  display: flex;
  align-items: center;
`;

export const CalculatorInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CalculatorKmInput = styled.input`
  width: 136px;
  font-size: 24px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #343741;
  color: #2C3A39;
`;

export const CalculatorKmInputStaticText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #2C3A39;
  margin: 0;
  margin-left: 6px;
  position: absolute;
  left: 55px;
`;

export const CalculatorKmButton = styled.button`
  width: 59px;
  height: 19px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #2C3A39;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  > img {
    margin-left: 5px;
  }
`;

export const CalculatorKmResultIconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 26px);
  z-index: 2;

  @media (max-width: 768px) {
    top: 100px;
  }
`;

export const CalculatorKmResultContainer = styled(CalculatorKmContainer)`
  width: 287px;
  height: 100%;
  margin-left: 16px;
  background-color: #FFC900;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-left: 0;
  }
`;

export const CalculatorKmResultTitle = styled.p`
  max-width: 226px;
  color: #2C3A39;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  line-height: 1.55;

  > strong {
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    background-color: #ffffff;
    margin-right: 6px;
    padding: 2px;
  }
`;

export const CalculatorKmResultData = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  margin-top: 2px;
  color: #2C3A39;
`;

export const ModalDiscountContainer = styled.div`
  width: 792px;
  height: calc(590px - 58px);
  padding: 21px 28.5px 31px 30.5px;
  background-color: #ffffff;
`;

export const ModalDiscountDescription = styled.p`
  font-size: 18px;
  line-height: 1.33;
  color: #2C3A39;
  margin: 0;
`;

export const ModalDiscountCalculationContainer = styled.div`
  height: 75px;
  display: flex;
  align-items: flex-start;
  margin-top: 22px;

  p {
    margin: 0;
  }

  > div {
    height: 100%;
    text-align: center;

    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > p:last-child {
        cursor: pointer;
      }
    }

    &:first-child,
    &:nth-child(3) {
      p {
        &:first-child {
          font-size: 14px;
          font-style: italic;
          font-weight: 300;
          color: #343741;
          line-height: 1.14;
        }

        &:nth-child(2) {
          font-size: 22px;
          font-weight: bold;
          color: #1a1446;
        }

        &:nth-child(3) {
          font-size: 12px;
          font-weight: italic;
          text-decoration: underline;
          color: #343741;
        }
      }
    }

    &:nth-child(2) {
      margin-left: 32px;
      margin-right: 23px;
    }

    &:nth-child(3) {
      > p {
        &:last-child {
          margin-top: 10px;
        }
      }
    }

    &:nth-child(4) {
      margin-left: 23px;
      margin-right: 23px;
    }

    &:nth-child(2),
    &:nth-child(4) {
      width: 46px;
      display: flex;
      align-items: center;

      > img {
        width: 100%;
        height: 46px;
        object-fit: cover;
      }
    }

    &:last-child {
      width: 126px;
      background-color: #1a1446;
      text-align: center;
      border-radius: 5px;
      padding: 5px 4px 7px 5px;

      > p {
        font-weight: bold;

        &:first-child {
          width: 117px;
          font-size: 14px;
          line-height: 1.14;
          color: #ffffff;
        }

        &:last-child {
          font-size: 22px;
          font-weight: bold;
          color: #ffd000;
          margin-top: 9px;
        }
      }
    }
  }
`;

export const CalculatorKmInputContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 14px;

  > div {
    width: 127px;
    height: 100%;
  }

  > div > label > div {
    margin: 0;
  }

  > button {
    width: 77px;
    height: 100%;
    margin-left: 7px;
  }
`;

export const ModalDiscountImageContainer = styled.div`
  width: 100%;
  height: 277px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 47px;
  }
`;

export const ModalAvisoContent = styled.div`
  padding: 21px 30px 35px;
  display: flex;
  flex-direction: column;
  max-width: 792px;
  gap: 6px;

  align-items: center;

  justify-content: space-between;

  img {
    height: auto;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
    color: #333;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
    width: 100%;

    & > div {
      margin-left: 38.5px;
      line-height: 1.33;

      & > div:nth-child(1) {
        color: #06748c;
        font-weight: 700;
      }

      & > div:nth-child(2),
      & > div:nth-child(3) {
        margin-top: 28.5px;
      }

      & > div:nth-child(4) {
        font-style: italic;
        font-size: 16px;
        margin-top: 28.5px;
      }
    }
  }
`;

export const Container3Value = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: flex-start !important;

    > button {
      margin: 5px 10px;
    }
  }
`;

export const Container3Group = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
