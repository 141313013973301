import React from 'react';

import About from './About';
import Banner from './Banner';
import Steps from './Steps';
import { FAQ } from './FAQ';
import HowItWorks from './HowItWorks';

const AutoControle = () => (
  <>
    <Banner />
    <About />
    <Steps />
    <HowItWorks />
    <FAQ />
  </>
);

export default AutoControle;
