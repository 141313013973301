import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerTitle = styled.div`
  width: 100%;

  h2 {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    color: #1a1446;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  h5 {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    margin-top: 14px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1.4375rem;
  }
`;

export const ContentLeft = styled.div`
  max-width: 489px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  h5 {
    font-size: 20px;
    font-weight: 500;
    color: #06748c;
    margin-top: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 20px;
    color: #343741;
    line-height: 1.5;
    margin: 0;
  }
`;

export const ContentCenter = styled.div`
  margin: 0 80px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const ContentRight = styled.div`
  max-width: 489px;
  text-align: center;

  h5 {
    font-size: 20px;
    font-weight: 500;
    color: #06748c;
    margin-top: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 20px;
    color: #343741;
    line-height: 1.5;
    margin: 0;
  }
`;

export const ContentBottom = styled.div`
  text-align: center;
  padding-bottom: 37px;
  margin-top: 30px;

  p {
    font-size: 20px;
    color: #1a1446;
  }
`;

export const CarContainer = styled.div``;

export const AutoControleIncludedContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1.75rem;
  }
`;

export const AutoControleOptionsContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ListIncluded = styled.ul`
  & {
    width: 33rem;
    height: 15.11125rem;
    font-size: 20px;
    list-style: none;
    margin: 0;
    border-right: 1px solid #343741;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      border-right: none;
      padding: 0;
    }

    h6 {
      font-size: 20px;
      color: #06748c;
      margin: 0;
      margin-left: 1.375rem;
      margin-bottom: 0.76875rem;
    }

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 0.76875rem;
      }

      &:nth-child(3) {
        align-items: flex-start;
      }

      img {
        margin-right: 0.28125rem;
      }
    }
  }
`;

export const ListOptions = styled.ul`
  & {
    font-size: 20px;
    list-style: none;
    margin: 0;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
      margin-top: 1.875rem;
    }

    h6 {
      font-size: 20px;
      color: #06748c;
      margin: 0;
      margin-left: 1.375rem;
      margin-bottom: 0.76875rem;
    }

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 0.76875rem;
      }

      img {
        margin-right: 0.28125rem;
      }
    }
  }
`;
