import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleSetLocation } from '~/store/modules/location';

export default function Navigation({ children, location }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(handleSetLocation(location));
  }, [dispatch, location]);

  return children;
}
