import React from 'react';

const questions = [
  {
    id: 'faq-elegibilidade',
    label: 'ELEGIBILIDADE',
    content: [
      {
        title: 'Para quem é indicado o AUTO Controle?',
        description:
          'O seguro é mais indicado para pessoas que dirigem pouco com veículo (até 500km por mês); têm hábito de utilizar transporte compartilhado (carros de APP) no seu dia-a-dia, ou utilizam o seu veículo com mais frequência aos finais de semana.',
      },
      {
        title: 'Em que locais o AUTO Controle está disponível? ',
        description:
          'Apenas para a área metropolitana da cidade de São Paulo. Por exemplo: cidades de São Paulo, Santo André, São Bernardo do Campo, São Caetano do Sul, Diadema, Barueri, Carapicuíba, Guarulhos, Poá, Taboão da Serra.',
      },
      {
        title: 'Há alguma restrição de idade dos condutores?',
        description:
          'Não há nenhuma restrição de perfil de condutor para a contratação do AUTO Controle.',
      },
      {
        title: 'A aceitação é para todos os veículos?',
        description:
          'Aceitação para veículos de passeio/picape nacional ou importado. A tecnologia Vai depende do veículo ter a porta OBD disponível.',
      },
      {
        title: 'O programa atende renovações congênere ou apenas seguro novo?',
        description: 'Podemos atender seguros novos e renovações congênere.',
      },
    ],
  },
  {
    id: 'faq-assistencia-cobertura',
    label: 'Assistências e Coberturas',
    content: [
      {
        title: 'Como funcionam as coberturas?',
        description:
          'Elas permanecem idênticas ao produto AUTO Perfil tradicional. Basicamente todas as coberturas e limites são iguais de uma apólice de 12 meses de vigência só que em 1 mês. Lembrando que é possível manter os mesmos parâmetros da apólice anterior.',
      },
      {
        title:
          'É possível ter coberturas adicionais de vidros e carro reserva?',
        description: 'Sim, ambas as cobertura podem ser contratadas.',
      },
      {
        title:
          'Minhas coberturas e assistências estão limitadas a alguma quilometragem?',
        description:
          'Independente da quilometragem percorrida, o segurado estará coberto de acordo com as coberturas e assistências contratadas na apólice.',
      },
    ],
  },
  {
    id: 'faq-bonus',
    label: 'Bônus',
    content: [
      {
        title: 'Como funciona a classe de bônus?',
        description:
          'A classe de bônus continua normalmente como nos demais produtos de Auto da Yelum. Para subir uma classe de bônus é necessário somar 12 meses de apólices vigentes. A perda da classe de bônus acontecerá conforme regulamento da Susep, por exemplo cancelar a apólice antes de completar 12 meses e não contratar um novo seguro antes de 3 meses.',
      },
      {
        title: 'O que acontece com a classe de bônus durante os meses?',
        description:
          'Ela seguirá ativa normalmente, e quando completar 12 meses de permanência no AUTO Controle, será somado uma classe de bônus para o segurado, que poderá ser utilizado em qualquer outro produto de auto.',
      },
      {
        title:
          'A regra de bônus é válida também para renovação congênere?  E o que acontece caso haja migração de produto?',
        description:
          'Sim, o bônus é válido para todos os casos. Após o término da vigência de 12 meses, a classe de bônus poderá ser utilizada para migração de uma congênere.',
      },
    ],
  },
  {
    id: 'faq-aplicativo',
    label: 'aplicativo',
    content: [
      {
        title: 'Por que é preciso instalar um aplicativo?',
        description:
          'O aplicativo é necessário para captar a quilometragem, através de fotos mensais do odômetro do veículo segurado. Com base no quanto dirige será calculado o preço do seu seguro.',
      },
      {
        title: 'Como encontro o aplicativo para fazer a instalação?',
        description:
          'O app AUTO Controle está disponível nas principais lojas de aplicativos: Apple Store, Google Play e Huawei AppGallery. Caso não encontre o aplicativo nas lojas, verifique se você se encontra dentro de território brasileiro e se o seu aparelho é compatível.',
      },
      {
        title: 'Qual o prazo para instalação do aplicativo?',
        description:
          'A Instalação deverá ocorrer em até 15 dias à partir da data de início de vigência da 1ª apólice. Em caso de instalação fora do prazo ou de não instalação, a seguradora se vê no direito de cobrar com base na quilometragem máxima de 1200km ou cancelar a apólice.',
      },
      {
        title: 'É possível verificar o histórico de quilometragem?',
        description:
          'Sim, o app AUTO Controle possui uma seção chamada Galeria de Odômetro, onde o segurado pode acessar todas as fotos de odômetros tiradas desde o início do seguro. Também há uma seção chamada Meu Consumo, com os valores consolidados de quilometragem e pagos pelos segurados a cada mês.',
      },
      {
        title: 'Quando preciso tirar a foto do odômetro?',
        description: (
          <>
            O segurado deve tirar uma foto de odômetro ao se cadastrar no
            aplicativo. Essa foto é obrigatória e faz parte do processo de
            cadastro no app. Sem ela, a seguradora se vê no direito de cobrar
            com base na quilometragem máxima de 1200km ou cancelar a apólice.
            <br />
            Depois de completar o cadastro no aplicativo, na tela de Meu Resumo,
            o segurado poderá saber qual o status da foto e quando será a
            próxima janela de envio de fotos. Também serão enviadas notificações
            e e-mails para os clientes, avisando quando devem tirar a foto
            mensal.
          </>
        ),
      },
      {
        title: 'Não recebo notificações ou e-mails do app, o que devo fazer?',
        description: (
          <>
            Se não estiver recebendo notificações em seu celular, verifique nas
            configurações do aparelho se o recebimento de notificações pelo
            aplicativo AUTO Controle está habilitado.
            <br />
            Se não estiver recebendo os avisos por e-mail, verifique primeiro a
            sua caixa de spam. Caso os e-mails caiam no spam, lembre-se de
            registrar nas configurações de sua caixa de e-mails que o endereço
            remetente das nossas comunicações é seguro. Se os e-mails não
            estiverem na caixa de spam, verifique se o e-mail cadastrado está
            correto.
          </>
        ),
      },
      {
        title: 'O que acontece no caso de desinstalação do aplicativo? ',
        description:
          'O aplicativo deverá permanecer instalado no celular durante toda vigência do contrato e caso haja a necessidade de removê-lo, para situações como manutenção ou mudança de celular, ele deverá ser recolocado imediatamente após o término das atividades. Caso o cliente deixe de enviar a foto por 2 meses seguidos ou 3 meses durante o ano, sem justificativa, a seguradora se vê no direito de cancelar a apólice.',
      },
      {
        title:
          'Estou com problemas técnicos com o aplicativo, o que devo fazer?',
        description: (
          <>
            Se estiver com problemas técnicos, preencha o formulário disponível
            neste link:{' '}
            <a href='https://airtable.com/shrQ8T7UYLm6FBLLF'>
              https://airtable.com/shrQ8T7UYLm6FBLLF
            </a>
            . A equipe técnica fará a análise do problema e o devido
            endereçamento.
          </>
        ),
      },
    ],
  },
  {
    id: 'faq-pagamento',
    label: 'Pagamento',
    content: [
      {
        title: 'Como é o cálculo da primeira e da segunda fatura?',
        description:
          'A primeira e segunda fatura tem preço fixo, não influenciada pela quilometragem.',
      },
      {
        title:
          'Como funciona o cálculo à partir da terceira fatura do produto AUTO Controle?',
        description:
          'O cálculo funcionará da seguinte maneira: Fatura = Preço base + (Custo por Km * a quilometragem). Onde o preço base é uma taxa fixa e independente de quilometragem e a outra parte é o valor proporcional aos quilômetros percorridos no mês.',
      },
      {
        title: 'Quais atividades impactam no prêmio?',
        description:
          'O valor varia conforme a quantidade de quilômetros andados durante o mês vigente.',
      },
      {
        title:
          'Se ultrapassar a quantidade de 1200 quilômetros no mês, qual será o valor da fatura?',
        description:
          'A cobrança a partir do terceiro mês é baseada nos Km rodados, limitados a 1200km por mês. Portanto, ao rodar mais do que 1200km não cobraremos nem mais um centavo por isso.',
      },
      {
        title:
          'O que fazer em caso de viagem, e o que acontece caso ocorram muitas viagens ao longo do ano?',
        description:
          'Entendemos que viagens são parte da rotina, assim se você viajar com o carro e rodar mais do que 1200km não cobraremos a mais por isso, somente o valor referente ao teto de 1200km, conforme a apólice do AUTO Controle.',
      },
      {
        title:
          'Caso o carro não seja usado no mês, qual será o valor da fatura?',
        description:
          'Se o carro ficar estacionado por todo o mês, o cliente pagará apenas a taxa fixa.',
      },
      {
        title:
          'É aceito andar, por exemplo, 300km em um mês e 100km em outro mês?',
        description:
          'Sim, podem rodar o quanto precisar e será pago o proporcional de cada quilometragem em cada mês.',
      },
      {
        title:
          'Como poderá saber o valor que pagará por mês antes do final dele?',
        description:
          'Será enviado por e-mail a prévia da fatura após 15 dia do início de cada apólice. Também poderá acompanhar as quilometragens a qualquer momento pelo aplicativo.',
      },
      {
        title: 'Quando ocorre a cobrança?',
        description:
          'O pagamento acontece em até 3 dias úteis após a contratação do produto AUTO Controle.',
      },
      {
        title: 'Qual o prazo para regularização da parcela?',
        description:
          'O prazo de regularização é de 7 dias, assim como no AUTO Perfil.',
      },
    ],
  },
  {
    id: 'faq-renovacao',
    label: 'Renovação',
    content: [
      {
        title: 'Como é feita a renovação no AUTO Controle?',
        description:
          'Por se tratar de um seguro mensal, a renovação irá ocorrer automaticamente mês a mês, e ao completar o 13º mês, e não tendo ocorrido nenhum sinistro, iremos acrescer uma classe de bônus.',
      },
      {
        title:
          'Se a apólice é sempre renovada, significa que todo mês será gerado um novo número de apólice?',
        description:
          'Sim, a cada mês será alterado o número da apólice, mas ao mencionar o número da primeira apólice ou seu CPF, a Yelum Seguradora consegue localizar todo o histórico do seu seguro.',
      },
    ],
  },
  {
    id: 'faq-sinistro',
    label: 'Sinistro',
    content: [
      {
        title: 'Como funciona o processo de sinistro no AUTO Controle?',
        description:
          'O processo de sinistro funciona normalmente, assim como nos demais produtos da Yelum Seguradora.',
      },
      {
        title:
          'Em caso de sinistro, como funciona o pagamento restante e devolução de valores?',
        description:
          'Por se tratar de um seguro mensal, não existe a opção de devolução neste produto, pois o não pagamento das faturas faz com que a apólice seja cancelada e assim não existirão renovações com residual de meses ou devolução.',
      },
    ],
  },
  {
    id: 'faq-cancelamento',
    label: 'Cancelamento',
    content: [
      {
        title: 'Como funciona o cancelamento, existe alguma multa?',
        description:
          'Cancelamento não tem nenhum custo extra, basta somente realizar todo o processo padrão de cancelamento. Se o cliente possuir um dispositivo Vai, deverá retornar o dispositivo para a empresa Vai, ou, se for um dispositivo Ituran, deve agendar uma nova data para desinstalar o rastreador. Caso o cliente só utilize o app AUTO Controle com a solução de foto de odômetro, não será necessário realizar nenhuma ação após o cancelamento.',
      },
      {
        title:
          'É possível cancelar a apólice AUTO Controle a qualquer momento?',
        description:
          'É possível cancelar a qualquer momento e a apólice atual terá vigência até o final dela.',
      },
      {
        title: 'Como cancelar o AUTO Controle?',
        description:
          'Basta entrar em contato com o seu corretor ou através do Serviço de Atendimento ao Cliente da Yelum Seguradora e solicitar o cancelamento.',
      },
    ],
  },
  {
    id: 'faq-franquia-vistoria',
    label: 'Franquia, Vistoria, Endosso e Suporte',
    content: [
      {
        title: 'É possível cotar veículos com a franquia reduzida?',
        description:
          'Sim, o motor de cálculo é o mesmo do produto de seguro tradicional Auto Perfil, mas vale a pena lembrar que o veículo precisa estar nos critérios elegíveis do AUTO Controle.',
      },
      {
        title:
          'É necessário vistoria prévia? E como funciona a solicitação ou a liberação de vistoria?',
        description:
          'A vistoria prévia, seja ela tradicional ou Auto Vistoria, é necessária. Sua solicitação ou liberação segue da mesma forma como nos produtos tradicionais Yelum Seguradora, como o Auto Perfil.',
      },
      {
        title:
          'É possível endossar, realizar alteração de condutor, para esse tipo de apólice?',
        description:
          'Infelizmente no momento, só será possível fazer alterações de endereço. Para outras mudanças, será necessário efetuar o cancelamento da apólice atual e recontratação em outro nome.',
      },
      {
        title: 'Caso tenha dúvidas sobre a fatura, o que deverá fazer?',
        description:
          'Entre em contato com o seu corretor ou com o Suporte do Auto Controle no WhatsApp 11 96381-9551 e mencionar no corpo do e-mail a dúvida em relação ao cálculo, anexando junto a fatura em questão.',
      },
    ],
  },
];

export default questions;
