export default {
  ACCOUNT: {
    POST_TOKEN_URL: '/accounts/v1/token/',
  },
  CALCULATOR: {
    POST_CALCULATOR_URL: '/calculator/v1/',
    GET_CALCULATOR_URL: '/calculator/v1/hash/',
    POST_PROMOTIONAL_DISCOUNT: '/calculator/v1/promotional-discount',
  },
  PDF: {
    GET_PDF_URL: '/calculator/v1/download-proposal/'
  }
};
