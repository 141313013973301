import React, { useEffect, useState, useRef } from 'react';

import { Wrapper } from '~/components/_helpers';

import interrogationIcon from './assets/interrogation.svg';
import checkedIcon from './assets/checked.svg';
import xIcon from './assets/x.svg';
import manWithNotebookIcon from './assets/manWithNotebook.png';
import calculatorPage from './assets/calculator_page.png';
import logoVai from './assets/logoVai.png';
import logoIturan from './assets/logoIturan.png';

import {
  Title,
  SubTitle,
  TipsContainer,
  FirstTip,
  SecondTip,
  FirstTipTitle,
  SecondTipTitle,
  FirstTipList,
  SecondTipList,
  TooltipJob,
  TooltipStudy,
  TooltipUpTo500Km,
  DoubtContainer,
  DoubtTitle,
  DoubtList,
  QuotationTipsContainer,
  QuotationContainer,
  QuotationTitle,
  ThirdTip,
  ThirdTipTitle,
  ContainerToDivideContent,
  ContentLeft,
  ContentRight,
  FourthTip,
  FourthTipTitle,
  FifthTip,
  FifthTipTitle,
  EmissionContainer,
  SixthTip,
  SixthTipTitle,
  SeventhTip,
  SeventhTipTitle,
  ContentBoxContainer,
  InformationsContainer,
  EighthTip,
  EighthTipTitle,
  NinthTip,
  NinthTipTitle,
  TenthTip,
  TenthTipTitle,
} from './styles';

const Tips = () => {
  const iconTooltipJob = useRef(null);
  const iconTooltipStudy = useRef(null);
  const iconTooltipUpTo500Km = useRef(null);

  const tooltipJob = useRef(null);
  const tooltipStudy = useRef(null);
  const tooltipUpTo500Km = useRef(null);

  const [jobTooltipVisible, setJobTooltipVisible] = useState(false);
  const [studyTooltipVisible, setStudyTooltipVisible] = useState(false);
  const [upTo500KmTooltipVisible, setUpTo500KmTooltipVisible] = useState(false);

  const [widthTooltipJob, setWidthTooltipJob] = useState(0);
  const [widthTooltipStudy, setWidthTooltipStudy] = useState(0);
  const [widthTooltipUpTo500Km, setWidthTooltipUpTo500Km] = useState(0);

  const renderTooltip = () => {
    setWidthTooltipJob(
      tooltipJob.current.getBoundingClientRect().left -
        iconTooltipJob.current.getBoundingClientRect().left
    );

    setWidthTooltipStudy(
      tooltipStudy.current.getBoundingClientRect().left -
        iconTooltipStudy.current.getBoundingClientRect().left
    );

    setWidthTooltipUpTo500Km(
      tooltipUpTo500Km.current.getBoundingClientRect().left -
        iconTooltipUpTo500Km.current.getBoundingClientRect().left
    );
  };

  useEffect(() => renderTooltip(), []);

  return (
    <Wrapper>
      <Title>Dicas de venda - como oferecer o AUTO Controle?</Title>

      <SubTitle>Definindo o perfil do cliente</SubTitle>

      <TipsContainer>
        <FirstTip>
          <FirstTipTitle>
            <div>1</div>
            <h3>Pergunte o principal uso do carro</h3>
          </FirstTipTitle>
          <FirstTipList
            tooltipJobIsVisible={jobTooltipVisible}
            tooltipStudyIsVisible={studyTooltipVisible}
            widthTooltipJob={widthTooltipJob}
            widthTooltipStudy={widthTooltipStudy}
          >
            <li>
              Trabalho
              <img
                src={interrogationIcon}
                alt='ícone com um símbolo de interrogação'
                onMouseEnter={() => setJobTooltipVisible(true)}
                onMouseLeave={() => setJobTooltipVisible(false)}
                ref={iconTooltipJob}
              />
              <TooltipJob
                ref={tooltipJob}
                tooltipJobIsVisible={jobTooltipVisible}
              >
                Apesar do uso do carro ser intenso, se a km percorrida for
                pouca, o AUTO Controle ainda pode ser uma ótima opção
              </TooltipJob>
            </li>
            <li>
              Estudo / Levar para escola
              <img
                src={interrogationIcon}
                alt='ícone com um símbolo de interrogação'
                onMouseEnter={() => setStudyTooltipVisible(true)}
                onMouseLeave={() => setStudyTooltipVisible(false)}
                ref={iconTooltipStudy}
              />
              <TooltipStudy
                ref={tooltipStudy}
                tooltipStudyIsVisible={studyTooltipVisible}
              >
                Apesar do uso do carro ser intenso, se a km percorrida for
                pouca, o AUTO Controle ainda pode ser uma ótima opção
              </TooltipStudy>
            </li>
            <li>
              Lazer
              <img src={checkedIcon} alt='ícone com um símbolo de ok' />
            </li>
            <li>
              Deslocamentos curtos
              <img src={checkedIcon} alt='ícone com um símbolo de ok' />
            </li>
            <li>
              Viagens frequentes
              <img src={xIcon} alt='ícone com um símbolo de X' />
            </li>
          </FirstTipList>
        </FirstTip>

        <SecondTip>
          <SecondTipTitle>
            <div>2</div>
            <h3>Descubra a quilometragem média que costuma percorrer</h3>
          </SecondTipTitle>
          <SecondTipList
            tooltipUpTo500KmIsVisible={upTo500KmTooltipVisible}
            widthTooltipUpTo500Km={widthTooltipUpTo500Km}
          >
            <li>
              Até 500 km por mês (ou até 17 km por dia)
              <img src={checkedIcon} alt='ícone com um símbolo de ok' />
            </li>
            <li>
              Entre 500 km até 900 km por mês
              <img
                src={interrogationIcon}
                alt='ícone com um símbolo de interrogação'
                onMouseEnter={() => setUpTo500KmTooltipVisible(true)}
                onMouseLeave={() => setUpTo500KmTooltipVisible(false)}
                ref={iconTooltipUpTo500Km}
                style={{ cursor: 'pointer' }}
              />
              <TooltipUpTo500Km
                ref={tooltipUpTo500Km}
                tooltipUpTo500KmIsVisible={upTo500KmTooltipVisible}
              >
                A economia do cliente será menor, percorrendo entre 500 a 900 km
              </TooltipUpTo500Km>
            </li>
            <li>
              Mais de 900 km por mês
              <img src={xIcon} alt='ícone com um símbolo de X' />
            </li>
          </SecondTipList>
        </SecondTip>
      </TipsContainer>

      <DoubtContainer>
        <DoubtTitle>
          <h3>Ainda tem dúvidas?</h3>
          <h3>Entenda melhor os hábitos do cliente</h3>
        </DoubtTitle>

        <DoubtList>
          <p>Veja se ele:</p>
          <li>
            - possui carro, mas prefere utilizar outros meios de transporte
          </li>
          <li>
            - ou possui mais de um carro na garagem, e faz pouco uso de um deles
          </li>
          <li>- ou não trabalha ou mudou os hábitos por conta da pandemia</li>

          <img
            src={manWithNotebookIcon}
            alt='Ilustração de um homem segurando um notebook no colo'
          />
        </DoubtList>
      </DoubtContainer>

      <QuotationContainer>
        <QuotationTitle>Cote, calcule e compartilhe</QuotationTitle>

        <QuotationTipsContainer>
          <ThirdTip>
            <ThirdTipTitle>
              <div>3</div>
              <h3>Cote o seguro do veículo</h3>
            </ThirdTipTitle>
            <p>
              Cote usando o multicálculo ou dentro dos sistemas da Yelum Seguradora e
              pegue como referência o valor do Auto Perfil.
            </p>
            <p>
              <strong>Lembre-se:</strong>O AUTO Controle usa como base o valor e
              coberturas do Auto Perfil.
            </p>
          </ThirdTip>

          <ContainerToDivideContent>
            <ContentLeft>
              <FourthTip>
                <FourthTipTitle>
                  <div>4</div>
                  <h3>Acesse a calculadora do AUTO Controle</h3>
                </FourthTipTitle>
                <p>
                  Com a calculadora do AUTO Controle você saberá exatamente
                  quanto o cliente pagará na 1ª e 2ª cobrança e qual será o
                  valor fixo e o valor por km que o cliente pagará à partir da
                  3ª cobrança.
                  <strong>
                  Basta pegar o valor do Auto Perfil e inserir no campo e
                  calcular.
                </strong>
                </p>
                
              </FourthTip>

              <FifthTip>
                <FifthTipTitle>
                  <div>5</div>
                  <h3>Compartilhe o cálculo com o seu cliente</h3>
                </FifthTipTitle>
                <p>
                  Após o cálculo, você terá a opção de enviar um link para seu
                  cliente com os valores calculados e outras informações sobre o
                  seguro AUTO Controle. Basta clicar em “Compartilhar” e enviar
                  o link para a pessoa.
                </p>
                <p>
                  <strong>Lembre-se:</strong>
                  Cada link de cliente é personalizado com o cálculo feito à
                  partir da cotação dele.
                </p>
              </FifthTip>
            </ContentLeft>

            <ContentRight>
              <img
                src={calculatorPage}
                alt='Imagem de um print da página inicial do Auto Controle'
              />
            </ContentRight>
          </ContainerToDivideContent>
        </QuotationTipsContainer>
      </QuotationContainer>

      <EmissionContainer>
        <SubTitle>Emissão e Aplicativo AUTO Controle</SubTitle>

        <SixthTip>
          <SixthTipTitle>
            <div>6</div>
            <h3>Emissão e Aplicativo AUTO Controle</h3>
          </SixthTipTitle>

          <p>
            Faça a emissão através do Meu Espaço Corretor e siga como de
            costume. O processo de transmissão ocorrerá da mesma forma que o
            Auto Perfil.
          </p>

          <p>
            <strong>Lembre-se:</strong>A apólice emitida é mensal e é renovada
            automaticamente.
          </p>
        </SixthTip>

        <SeventhTip>
          <SeventhTipTitle>
            <div>7</div>
            <h3>Instalação do aplicativo de captura de odômetro</h3>
          </SeventhTipTitle>

          <p>
            A instalação do aplicativo AUTO Controle é obrigatória pelo
            cliente, uma vez que à partir dos dados coletados através das fotos
            de odômetro tiradas pelos segurados é que nos fornece condições de
            realizar o cálculo da quilometragem percorrida e realizar a
            cobrança. Os clientes devem tirar uma foto do odômetro ao se
            cadastrar e depois todos os meses, durante a duração do seguro.
            Notificações e e-mails avisarão quando deve ser tiradas as fotos.
            <br />
            Caso o cliente não possa tirar a foto, por motivos de sinistro
            (carro na oficina, por exemplo) ou de férias, poderá ser feita a
            justificativa dentro do próprio aplicativo.
          </p>
        </SeventhTip>
      </EmissionContainer>

      <InformationsContainer>
        <SubTitle>Informações adicionais para o segurado</SubTitle>

        <EighthTip>
          <EighthTipTitle>
            <div>8</div>
            <h3>O que aguarda o cliente?</h3>
          </EighthTipTitle>

          <ul>
            <h4>Após a transmissão da apólice, o cliente:</h4>
            <li>
              Receberá um e-mail de boas-vindas com as principais informações do
              seguro AUTO Controle
            </li>
            <li>Realizará a vistoria em até 7 dias após a contratação</li>
            <li>
              Receberá as informações de instalação do aplicativo. O cliente
              deve instalar e criar uma conta no app em até 15 dias após o
              início da vigência da 1ª apólice
            </li>
            <li>
              Dentro no aplicativo o cliente terá acesso às quilometragens,
              dados da apólice e contato, informação de valores e notificações.
            </li>
          </ul>
        </EighthTip>
      </InformationsContainer>

      <InformationsContainer>
        <SubTitle>Clientes antigos com rastreador instalado</SubTitle>

        <NinthTip>
          <NinthTipTitle>
            <div>9</div>
            <h3>Clientes antigos com rastreador instalado</h3>
          </NinthTipTitle>

          <p>
            Todos os clientes AUTO Controle que utilizam a tecnologia
            OBD ou Ituran, continuarão usando os dispositivos instalados para a
            captura de quilometragem. Os clientes que quiserem mudar para foto
            de odômetro poderão solicitar a migração.
          </p>
        </NinthTip>

        <TenthTip>
          <TenthTipTitle>
            <div>10</div>
            <h3>Tipos de dispositivo de rastreamento</h3>
          </TenthTipTitle>

          <div>
            <p>
              Os dispositivos que os clientes antigos podem ser de 2
              fornecedoras diferentes. Abaixo as informações sobre elas.
            </p>
            <p>
              <strong>Lembre-se:</strong> As apólices emitidas após a data de
              mudança da tecnologia para captura de quilometragem devem usar a
              captura de foto de odômetro via aplicativo (ver item 7 desta
              seção).
            </p>
          </div>

          <ContentBoxContainer>
            <div>
              <img
                src={logoVai}
                alt='Logotipo da marca Vehicle Artificial Intelligence'
              />
            </div>
            <div>
              <ul>
                <h4>Rastreador Vai</h4>
                <li>Dispositivo OBDII</li>
                <li>Envio para endereço do segurado</li>
                <li>Fácil conectividade e instalação</li>
                <li>Aplicativo Vai</li>
              </ul>
            </div>
            <div>
              <ul>
                <h4>Ideal para os clientes que:</h4>
                <li>Não se encaixam no perfil Ituran</li>
                <li>Querem mais praticidade</li>
                <li>Já são clientes Vai</li>
              </ul>
            </div>
          </ContentBoxContainer>

          <ContentBoxContainer>
            <div>
              <img src={logoIturan} alt='Logotipo da marca Ituran' />
            </div>
            <div>
              <ul>
                <h4>Rastreador Ituran</h4>
                <li>Dispositivo GPRS</li>
                <li>
                  Necessário agendar instalação (com opção de “Instalação em
                  casa”)
                </li>
                <li>
                  Instalação realizada por um técnico responsável da Ituran
                </li>
                <li>Aplicativo Auto Controle</li>
              </ul>
            </div>

            <div>
              <ul>
                <h4>Ideal para os clientes que:</h4>
                <li>
                  Tenham veículos elegíveis ao dispositivo antifurto Ituran
                </li>
                <li>Preferem que um técnico instale o dispositivo</li>
                <li>Já são clientes Ituran</li>
              </ul>
            </div>
          </ContentBoxContainer>
        </TenthTip>
      </InformationsContainer>
    </Wrapper>
  );
};

export default Tips;
