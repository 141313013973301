import React from 'react';

import plusIcon from './assets/plus.svg';
import multiplicationIcon from './assets/multiplication.svg';
import arrowRightIcon from './assets/arrow_right.svg';

import { Wrapper } from '~/components/_helpers';

import {
  Container,
  Title,
  SubTitle,
  CollectionContainer,
  Collection,
  ArrowRight,
  UseCalculator,
} from './styles';
import { navigate } from '@reach/router';

const HowItWorks = () => {
  return (
    <Wrapper howItWorks mobile_mt="0px">
      <Container>
        <Title>
          E como funciona a cobrança do AUTO Controle?
        </Title>
        <SubTitle>
          A cobrança do AUTO Controle é realizada mensalmente.
          A 1ª e 2ª cobrança possuem um valor fixo e à partir da 3ª cobrança,
          o valor varia de acordo com a quilometragem percorrida.
          A renovação é mensal e automática.
        </SubTitle>

        <CollectionContainer>
          <Collection>
            <h4>1ª cobrança</h4>
            <p>Valor Fixo</p>
          </Collection>

          <ArrowRight>
            <img src={arrowRightIcon} alt="Ícone de seta" />
          </ArrowRight>

          <Collection>
            <h4>2ª cobrança</h4>
            <p>Valor Fixo</p>
          </Collection>

          <ArrowRight>
            <img src={arrowRightIcon} alt="Ícone de seta" />
          </ArrowRight>

          <Collection>
            <h4>3ª cobrança</h4>
            <p>Prêmio fixo mensal <img src={plusIcon} alt="Ícone de um símbolo de mais" />
              <span>(</span> Valor do km <img src={multiplicationIcon} alt="Ícone de um símbolo de multiplicação" /> km percorrida <span>)</span>
            </p>
          </Collection>
        </CollectionContainer>

        <UseCalculator>
          <h4>
            Use a calculadora para descobrir quanto seu cliente irá pagar
          </h4>
          <button type="button" onClick={() => navigate('/')}>
            Calcule o AUTO Controle
          </button>
        </UseCalculator>
      </Container>
    </Wrapper>
  );
};

export default HowItWorks;
