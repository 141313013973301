import React from 'react';
import jwt from 'jsonwebtoken';

import { Wrapper } from '~/components/_helpers';

import {
  Container,
  TitlePage,
  Content,
  TitleContent,
  TextContent,
  VideoContainer,
  VideoContent,
  Video,
  TextVideoContent,
  TextVideo,
  InformativeContent,
  InformativeCard,
  InformativeImg,
  TitleHelp,
  HelpContent,
  TextHelp,
  TextInformativeContent,
  TextInformative,
  AppContent,
  AppContentContainer,
  AppImage,
  StoreContainer,
  StoreItemContainer,
  TextBoldContent,
  StoreImage,
  AppLink,
} from './styles';

import InformativeCalc from './assets/img_comocalcular.png';
import InformativeClient from './assets/img_perfilcliente.png';
import InformativeWhy from './assets/img_porqueoferecer.png';
import InformativePubli from './assets/img_publi.png';
import InformativeTrainImg from './assets/img_treinamento.png';
import InformativeTrainPdf from './assets/pdf_treinamento.pdf';
import InformativePersonasImg from './assets/img_personas.png';
import InformativePersonasPdf from './assets/pdf_personas.pdf';
import App01 from './assets/img_app01.png';
import App02 from './assets/img_app02.png';
import App03 from './assets/img_app03.png';
import PlayStore from './assets/play_store.png';
import AppleStore from './assets/apple_store.png';
import { LINK_APPLE_STORE, LINK_GOOGLE_PLAY } from '~/utils/helpers';

export default function Support() {
  const payload = jwt.decode(localStorage.getItem('@autoControle/token'));
  const userId = payload.user_id;

  const informatives = [
    {
      src: InformativeTrainPdf,
      img: InformativeTrainImg,
      title: 'Treinamento Auto Controle',
    },
    { src: InformativeCalc, img: InformativeCalc, title: 'Como calcular' },
    {
      src: InformativeClient,
      img: InformativeClient,
      title: 'Descubra se é a opção certa',
    },
    {
      src: InformativeWhy,
      img: InformativeWhy,
      title: 'Motivos para oferecer',
    },
    {
      src: InformativePubli,
      img: InformativePubli,
      title: 'Divulgue para seus clientes',
    },
    {
      src: InformativePersonasPdf,
      img: InformativePersonasImg,
      title: 'Personas Auto Controle',
    },
  ];

  const medias = [
    {
      src: 'https://www.youtube.com/embed/KJXscBjb2PM',
      type: 'video/mp4',
      text: 'Treinamento AUTO Controle',
    },
  ];

  const InformativeCards =
    informatives &&
    informatives.map((info, idx) => (
      <InformativeCard key={`informative-${idx}`}>
        <InformativeImg
          onClick={() => {
            window.dataLayer.push({
              event: 'clickInformatives',
              user_id: userId,
              file: info.title,
            });
            window.open(info.src, '_blank');
          }}
          src={info.img}
          alt={info.title}
        />
        <TextInformativeContent>
          <TextInformative>{info.title}</TextInformative>
        </TextInformativeContent>
      </InformativeCard>
    ));

  const VideosComponent =
    medias &&
    medias.map(video => (
      <VideoContent key={video.src}>
        <Video
          src={`${video.src}?rel=0`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
        <TextVideoContent>
          <TextVideo>{video.text}</TextVideo>
        </TextVideoContent>
      </VideoContent>
    ));

  return (
    <Wrapper faq mobile_mt='0px'>
      <Container>
        <TitlePage>Material de Apoio</TitlePage>
        <Content>
          <TitleContent>Vídeos explicativos</TitleContent>
          <TextContent>
            Assista estes vídeos curtos preparados especialmente para explicar
            como funciona o site e como você pode calcular o AUTO Controle para
            o seu cliente.
          </TextContent>
          <VideoContainer>{VideosComponent}</VideoContainer>
        </Content>
        <Content>
          <TitleContent>Informativos</TitleContent>
          <InformativeContent>{InformativeCards}</InformativeContent>
        </Content>
        <AppContentContainer>
          <TitleContent>Aplicativo AUTO Controle</TitleContent>
          <AppContent>
            <div>
              <AppImage src={App01} alt='Imagem do app' />
              <AppImage src={App02} alt='Imagem do app' />
              <AppImage src={App03} alt='Imagem do app' />
            </div>
            <StoreContainer>
              <TextContent>
                O aplicativo AUTO Controle é parte fundamental já que o
                cliente deve informar mensalmente a quilometragem para que seja
                possível calcular o valor do prêmio para a renovação.
              </TextContent>

              <StoreItemContainer>
                <TextBoldContent>Baixe agora:</TextBoldContent>
                <AppLink href={LINK_GOOGLE_PLAY} target='blank'>
                  <StoreImage src={PlayStore} alt='Google Play Store' />
                </AppLink>
                <AppLink href={LINK_APPLE_STORE} target='blank'>
                  <StoreImage src={AppleStore} alt='Apple Play Store' />
                </AppLink>
              </StoreItemContainer>
            </StoreContainer>
          </AppContent>
        </AppContentContainer>
      </Container>
    </Wrapper>
  );
}
