import styled from 'styled-components';

export const Header = styled.header`
  height: 6.99375rem;
  background-color: transparent;
  padding-top: 1.875rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  @media (max-width: 768px) {
    padding-left: 0.875rem;
  }

  @media (max-width: 414px) {
    padding-top: 1.5625rem;
    padding-left: 0;
    padding-right: 0;
  }

  img {
    @media (max-width: 768px) {
      width: 12.50625rem;
      height: 2.9625rem;
    }
    @media (max-width: 576px) {
      width: 12.50625rem;
      height: 2.9625rem;
      object-fit: cover;
    }
  }
`;
