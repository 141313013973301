import styled from 'styled-components';

import { FlexContent } from '~/components/_helpers/basic-diagramation';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.3375rem;
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 300;
  color: #1a1446;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: 300;
  color: #343741;
  margin: 1.0625rem 0 0 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IconsBorderTop = styled.div`
  height: 2.9625rem;
  margin-top: 1.96875rem;
  border-top: 1px solid #78e1e1;
  border-right: 1px solid #78e1e1;
  border-left: 1px solid #78e1e1;
`;

export const IconsBorderBottom = styled.div`
  height: 2.9625rem;
  border-bottom: 1px solid #78e1e1;
  border-right: 1px solid #78e1e1;
  border-left: 1px solid #78e1e1;
`;

export const IconsContent = styled.div`
  display: flex;

  .swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-ios {
    .swiper-wrapper {
    }
  }

  .swiper-slide {
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 10.93rem;
  }

  & {
    & + & {
      margin-left: 3.94375rem;
    }

    &:nth-child(1) {
      max-width: 10.9375rem;
      margin-left: 2.93125rem;

      @media (max-width: 768px) {
        width: auto;
        max-width: inherit;
        margin-left: 0.3125rem;
      }
    }

    &:nth-child(2) {
      max-width: 9.0625rem;

      @media (max-width: 768px) {
        margin-left: 0.3125rem;
      }
    }

    &:nth-child(3) {
      max-width: 11.75rem;
      margin-left: 2.1875rem;
    }

    &:nth-child(4),
    &:nth-child(5) {
      max-width: 10.0625rem;
    }

    &:nth-child(5) {
      margin-right: 3.3rem;
    }
  }

  img {
    @media (max-width: 768px) {
      width: 5.175rem;
      height: 5.2375rem;
    }
  }

  > p {
    max-width: 11.75rem;
    text-align: center;
    color: #06748c;
  }
`;

export const Content = styled(FlexContent)`
  display: flex;
`;

export const ContentMessage = styled(Content)`
  background-color: #fff;
  border-radius: 5px;
  padding: 0 30px;
  max-width: 60em;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const SideContent = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }

  .active {
    color: #1a1446;
  }

  .last {
    border-bottom: none;
  }

  li {
    color: #06748c;
    padding: 10px 10px 15px 10px;
    border-bottom: 2px solid grey;
    font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    cursor: pointer;
  }
`;
