import { navigate } from '@reach/router';

export default class TokenMiddleware {
  onRequest(config) {
    const token = localStorage.getItem('@autoControle/token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  onResponse(config) {
    if (config.response && config.response.status === 401) {
      localStorage.removeItem('@autoControle/token');
      const error = JSON.parse(config.response.data);
      navigate('/broker');
      return { error };
    }
    return config;
  }
}
