import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import { RS } from './styles';

export default function InputCurrency({
  name,
  label,
  error,
  initialValue,
  onChange,
  placeholder,
  disabled,
}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!initialValue) return;
    setValue(
      initialValue
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
        .replace(/(\d)(\d{3}),/g, '$1.$2,')
    );
  }, [initialValue]);

  return (
    <Input
      icon={<RS />}
      type="text"
      name={name}
      label={label}
      error={error}
      value={value}
      placeholder={placeholder}
      maxLength={10}
      disabled={disabled}
      onChange={e => {
        const val = String((e.target.value.replace(/\D/g, '') / 100).toFixed(2))
          .replace('.', ',')
          .replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4,')
          .replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
          .replace(/(\d)(\d{3}),/g, '$1.$2,');
        onChange(val);
        setValue(val);
      }}
      textAlign="right"
    />
  );
}

InputCurrency.defaultProps = {
  error: '',
  placeholder: '0,00',
  initialValue: 0,
  label: '',
  disabled: false,
};

InputCurrency.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
