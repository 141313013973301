import styled from 'styled-components';

import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;

  > div {
    padding: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px;

    > div {
      padding: 0;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 30px;
  }
`;

export const Content = styled(FlexContent)`
  display: flex;
  align-items: center;
  background: #FFC900;
  color: #2C3A39;

  p,
  span {
    font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
  }
`;

export const ContentMessage = styled(Content)`
  background-color: #fff;
  border-radius: 5px;
  padding: 0 30px;
  max-width: 60em;
  color: #2C3A39;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const SideContent = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #2C3A39;

  @media(max-width: 576px) {
    width: 100%;
    text-align: center;
  }

  .active {
    color: #2C3A39;
  }

  .last {
    border-bottom: none;
  }

  li {    
    padding: 10px 10px 15px 10px;
    border-bottom: 2px solid grey;
    font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    cursor: pointer;
  }
`;
