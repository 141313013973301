import styled from 'styled-components';
import { Link } from '@reach/router';

export const ContentHeader = styled.header`
  grid-area: header;
  z-index: 999;
  width: 100%;
  height: 80px;
  background-color: #2C3A39;
  color: #2C3A39;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 576px) {
    height: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;

  > div {
    flex: 1;

    button {
      float: right;
      margin-right: 15px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 10px 0;

    button {
      padding: 10px;
      max-width: 125px;
    }
  }
`;

export const Logo = styled(Link)`
  flex: 0.5;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2f3a56;
  margin-right: 75px;

  @media (max-width: 1200px) {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    img {
      max-width: 220px;
    }
  }

  @media (max-width: 400px) {
    img {
      height: 40px;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    margin: 20px 0;
  }

  a {
    font-size: 18px;
    text-decoration: none;
    color: #FFC900;

    &:last-child {
      @media (max-width: 576px) {
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    & + a {
      margin-left: 53px;

      @media (max-width: 576px) {
        margin-left: 30px;
      }
    }
  }

  .active {
    font-weight: 500;
  }
`;

export const ButtonCalculator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    button {
      max-width: 234px;
      margin-right: 0;

      @media (max-width: 576px) {
        max-width: 168px;
      }
    }
  }
`;
