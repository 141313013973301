export const LINK_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.appautocontroleui';
export const LINK_APPLE_STORE =
  'https://apps.apple.com/us/app/liberty-auto-controle/id1599833455';

export const scrollToTop = () => window.scrollTo(0, 0);

export const removeCurrencyMask = value => {
  if (!value || value === '') return 0;
  return Number(value.replace(/[^0-9,]+/g, '').replace(',', '.'));
};

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatNumber = number =>
  new Intl.NumberFormat('pt-BR').format(number);

export const formatPriceToWord = value => {
  const formattedValue = String(formatNumber(parseInt(value, 10)));
  const Valuesplit = formattedValue.split('.');
  const { length } = Valuesplit;

  switch (true) {
    case length <= 1: {
      const hundred = Math.round(Valuesplit[0]);
      if (hundred <= 0) return '0';
      return `${hundred} ${hundred <= 1 ? 'real' : 'reais'}`;
    }

    case length <= 2: {
      const thousand = Valuesplit[0];
      let hundred = Math.round(Valuesplit[1]);
      hundred = (hundred / 100).toFixed(0);

      return `${thousand + (hundred > 0 ? `.${hundred}` : '')} mil`;
    }

    case length <= 3: {
      const million = Valuesplit[0];
      let thousand = Math.ceil(Valuesplit[1] * 10) / 10;
      thousand = (thousand / 100).toFixed(0);

      return `${
        million +
        (thousand > 0 ? `.${thousand}` : '') +
        (million <= 1 && thousand <= 0 ? ' milhão' : ' milhões')
      }`;
    }

    case length > 3: {
      return 'Acima de milhões';
    }

    default:
      return value;
  }
};
