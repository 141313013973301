import React from 'react';
import useWindowSize from '@rooks/use-window-size';

import { Wrapper } from '~/components/_helpers';

import Buttons from './Buttons';

import Woman from './assets/woman_faq.svg';

import { Container, Content, ContentImg, ContentTitle } from './styles';

export default function FAQ() {
  const size = useWindowSize();

  return (
    <Wrapper faq mobile_mt='0px' width={size.innerWidth}>
      <Container>
        <Content size={0.5}>
          <ContentImg>
            <img src={Woman} alt='mulher com tablet nos braços' width={"100px"} height={"100px"} background-position={"center center"}   background-repeat={"no-repeat"}
/>
          </ContentImg>
          <ContentTitle>
            <h1>Dúvidas sobre como funciona o AUTO Controle?</h1>
          </ContentTitle>
        </Content>

        <Content>
          <Buttons />
        </Content>
      </Container>
    </Wrapper>
  );
}
