import constant from '~/api/constant';
import api from '~/api';

const { CALCULATOR } = constant;

export const postCalculator = (data) => {
  return api.post(CALCULATOR.POST_CALCULATOR_URL, data);
};

export const getCalculator = (code) => {
  return api.get(CALCULATOR.GET_CALCULATOR_URL + code);
};

export const postPromotionalDiscount = (data) => {
  return api.post(CALCULATOR.POST_PROMOTIONAL_DISCOUNT, data);
};
