import axios from 'axios';

console.log(`${process.env.REACT_APP_PRODUCTION_HOST}`)

const api = axios.create({
  
  baseURL: `${process.env.REACT_APP_PRODUCTION_HOST}/${process.env.REACT_APP_PRODUCTION_PATH}`,
  timeout: 5000,
});

export default api;
