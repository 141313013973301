import { navigate } from '@reach/router';

export default class LoaderMiddleware {
  constructor(setValues) {
    this.setValues = setValues;
    this.reject = null;
  }

  onRequest(config) {
    this.reject = config.loaderReject;
    if (!this.reject) {
      this.setValues({
        children: true,
        loading: true,
        error: false,
        refused: false,
      });
    }
    return config;
  }

  onResponse(response) {
    if (!this.reject) {
      this.setValues({
        children: true,
        loading: false,
        error: false,
        refused: false,
      });
    }
    return response;
  }

  onResponseError(config) {
    if (
      !this.reject && 
      config.response &&
      config.response.status === 400 &&
      JSON.parse(config.response.data).code &&
      JSON.parse(config.response.data).code[0] === 'Must be a valid UUID.'
    ) {
      localStorage.removeItem('@autoControle/token');
      navigate('/broker');
    } else {
      const unauthorized = config.response 
        && config.response.status === 401;

      this.setValues({
        children: unauthorized,
        loading: false,
        error: !unauthorized,
        refused: false,
      });
    }
    return config;
  }
}
