const Types = {
  SET_LOCATION: '@location/SET_LOCATION',
};

const initialState = {
  location: null,
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_LOCATION: {
      const { location } = action.payload;
      return { ...state, location };
    }

    default:
      return { ...state };
  }
};

export const handleSetLocation = location => ({
  type: Types.SET_LOCATION,
  payload: { location },
});
