import styled from 'styled-components';
import { darken } from 'polished';
import { setTheme, setSize } from './properties';

const Button = styled.button.attrs(props => ({
  theme: props.theme,
  size: props.size,
}))`
  color: ${props => setTheme(props).color};
  background-color: ${props => setTheme(props).background};
  border: ${props => setTheme(props).border};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: ${props => setSize(props).fontSize};
  font-weight: bold;
  transition: background-color 0.3s;
  padding: ${props => setSize(props).padding};
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => darken(0.05, setTheme(props).background)};
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;

export default Button;
