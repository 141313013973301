import React from 'react';
import Collapsible from 'react-collapsible';

import { Content, Wrapper, Container } from './styles';
import ArrowRight from './assets/arrow_right.svg';
import ArrowDown from './assets/arrow_down.svg';

const questions = [
  {
    title: 'Quem são as pessoas que podem participar do AUTO Controle?',
    content: `O seguro AUTO Controle é indicado para:
    - Quem percorre poucos quilômetros por mês (até 500km por mês);
    - Quem possui carro, mas prefere utilizar outros meios de transporte;
    - Quem possui mais de um carro na garagem, e faz pouco uso de um deles;
    - Quem utiliza o carro com frequência, mas percorre distâncias pequenas (até aproximadamente 17 km por dia).`,
  },
  {
    title: 'Como funciona a renovação do seguro?',
    content: `Por se tratar de um seguro mensal, a renovação irá ocorrer automaticamente mês a mês, e ao completar o 13º mês, e não tendo ocorrido nenhum sinistro, iremos acrescer uma classe de bônus.`,
  },
  {
    title: 'Por que é necessário instalar um aplicativo? ',
    content: `O aplicativo é necessário para enviar as fotos de odômetro do veículo. Com base no quanto dirige será calculado o preço do seu seguro.`,
  },
  {
    title: 'Como o cliente pode saber o valor que pagará por mês?',
    content: `Será enviado por e-mail a prévia da fatura após 15 dias do início de cada apólice. Também poderá acompanhar as quilometragens, a qualquer momento, pelo aplicativo do programa. No final da vigência de cada apólice será enviada a fatura com o valor a pagar e um detalhamento da quilometragem percorrida durante o mês.`,
  },
];

const Title = ({ title, icon }) => {
  return (
    <div>
      <img style={{ marginRight: '15px' }} src={icon} alt="Arrow right" />
      {title}
    </div>
  );
};

const formatFirstQuestion = (content) => {
  const [
    firstParagraph,
    secondParagraph,
    thirdParagraph,
    fourthParagraph,
    fifthParagraph,
  ] = content.split('-');

  return (
    <>
      <p style={{marginBottom: '10px'}}>{firstParagraph}</p>
      <p style={{margin: '10px 0'}}>- {secondParagraph}</p>
      <p style={{margin: '10px 0'}}>- {thirdParagraph}</p>
      <p style={{margin: '10px 0'}}>- {fourthParagraph}</p>
      <p style={{margin: '10px 0'}}>- {fifthParagraph}</p>
    </>
  );
};

export default function Questions() {
  return (
    <Wrapper>
      {questions.map((el, idx) => (
        <Container key={idx}>
          <Content>
            <Collapsible
              trigger={<Title title={el.title} icon={ArrowRight} />}
              triggerWhenOpen={<Title title={el.title} icon={ArrowDown} />}
            >
              {
                el.title === 'Quem são as pessoas que podem participar do AUTO Controle?'
                  ? formatFirstQuestion(el.content)
                  : <p>{el.content}</p>
              }
            </Collapsible>
          </Content>
        </Container>
      ))}
    </Wrapper>
  );
}
