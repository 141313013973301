import styled from 'styled-components';
import { ContainerFlex } from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #2C3A39;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 590px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 30px;
    padding: 30px 15px;
  }
`;

export const Content = styled.div`
  flex: ${props => props.size};
  margin-right: 16px;

  @media (max-width: 768px) {
    margin: 0;
  }

  img {
    margin-top: 3px;
  }

  button {
    width: 100%;
    margin-bottom: ${props => (props.error ? '25px' : 0)};
  }
`;
