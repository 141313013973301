import React from 'react';
import { Wrapper } from '~/components/_helpers';
import { 
    Container,
    Content,
    Background,
    Km,
    Arrow,
    Price,
    Car,
    Values
} from './styles';

import background from './assets/background.svg';
import veiculo from './assets/veiculo.svg';
import arrow from './assets/arrow.svg';
export default function Banner() {
  return (
    <Wrapper>
        <Container>
            <Content size={0.5}>
                <h1>Com o AUTO Controle quanto menos você anda, menos você paga </h1>
                <p>
                    O AUTO Controle é um seguro mensal por quilômetro feito 
                    especialmente para quem utiliza pouco o carro, mas não abre mão de 
                    toda a proteção e pode economizar até 50% no mês. 
                </p>
            </Content>
            <Content>
                <Background src={background} alt="plano de fundo cinza" />
                <Values>
                <Km>3</Km><Km>1</Km><Km>0</Km>
                <Arrow src={arrow} alt="seta apontando para direita" />
                <Price>R$ 262,79</Price>
                </Values>
                <Car src={veiculo} alt="veículo" />
            </Content>
        </Container>
    </Wrapper>
  );
}
