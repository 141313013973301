import styled from 'styled-components';

import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const MainSection = styled.section`
  height: 425.6px;
  position: relative;

  @media (max-width: 768px) {
    height: 15.625rem;
  }
`;

export const Container = styled(ContainerFlex)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled(FlexContent)``;

export const Background = styled.div`
  width: 100%;
  height: 492.63px;
  position: relative;

  > img {
    width: 101.5%;
    height: 24rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: -10px;

    @media (max-width: 768px) {
      width: 102.5%;
      height: 16.23rem;
    }

    @media (max-width: 578px) {
      height: 16.23rem;
    }
  }
`;

export const MainSectionWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  > div {
    @media (max-width: 768px) {
      overflow: inherit;
    }
  }
`;

export const ArrowRightContainer = styled.div`
  width: 39.15625rem;
  height: 16.5625rem;
  position: relative;
  margin-top: 9.1875rem;

  @media (max-width: 768px) {
    width: 14.5rem;
    height: 10.625rem;
    margin-top: 3rem;
  }

  @media (max-width: 578px) {
    margin-top: 0.375rem;
  }

  > img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    @media (max-width: 768px) {
      width: 14.5rem;
      object-fit: cover;
    }
  }
`;

export const ArrowRightTitle = styled.h1`
  width: 30.625rem;
  position: absolute;
  top: 3.25rem;
  right: 2.236111111111111em;
  bottom: 2.75em;
  left: 2.2777777777777777em;
  font-size: 36px;
  margin: 0;
  color: #1a1446;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1rem;
    max-width: 10.25rem;
    top: 0.5rem;
    left: 2.5rem;
  }

  @media (max-width: 414px) {
    top: 0.93125rem;
  }
`;

export const ArrowRightSubtitle = styled.h2`
  font-size: 22px;
  font-weight: 300;
  position: absolute;
  top: 8.318181818181818em;
  left: 3.727272727272727em;
  margin: 0;
  color: #1a1446;
  z-index: 1;

  @media (max-width: 768px) {
    max-width: 10.25rem;
    font-size: 16px;
    top: 6.9375rem;
    left: 2.5rem;
  }

  @media (max-width: 414px) {
    top: 7.25rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const CarContainer = styled.div`
  width: 32.6625rem;
  height: 25.1625rem;
  position: absolute;
  top: 1.4375rem;
  right: 0;

  @media (max-width: 768px) {
    height: 14.18125rem;
    font-size: 16px;
    top: -0.6875rem;
    right: -7.9375rem;
  }

  @media (max-width: 576px) {
    width: auto;
    position: absolute;
    top: -3.125rem;
    right: -1rem;
  }

  @media (max-width: 500px) {
    right: -8.3125rem;
  }

  @media (max-width: 375px) {
    right: -10.3125rem;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 576px) {
    }
  }
`;
