import styled from 'styled-components';

export const modalStyles = {
  content: {
    width: window.innerWidth < 576 && '100%',
    height: window.innerWidth < 576 && '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    marginRight: '-30%',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 9999,
  },
};

export const ModalHeader = styled.div`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.paddingHeader ? props.paddingHeader : '18px'};
  z-index: 99;

  border-bottom: solid 1px #d2d2d2;
  background-color: #ffffff;
`;

export const ModalTitle = styled.p`
  margin: 0;
  color: #1b1247;
  font-size: 18px;
`;

export const ModalClose = styled.img`
  cursor: pointer;

  &:hover {
    filter: invert(1);
  }
`;
