import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  background-color: #2C3A39;
  width: 100%;
  padding: 43px 83px;
  grid-area: footer;
  justify-content: center;
  align-items: center;
  

  ${({ brokerInUrl }) =>
    brokerInUrl &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    `};

  small {
    color: white;
    font-size: 12px;
    margin-right: 5%;

    & + small {
      margin-right: 0;
    }

    @media (max-width: 1075px) {
      display: block;
      margin: 0 0 5px 80px;
    }

    @media (max-width: 960px) {
      margin: 0 0 5px 15px;
    }

    @media (max-width: 768px) {
      margin: 0 0 5px 10px;
    }
  }

  @media (max-width: 1290px) {
    padding: 43px 12px;
  }
  
`;

export const FooterContent = styled.footer`
  display: flex;
  margin: auto;
  justify-content: space-between;  
  align-items: start;  
  max-width: 1200px;

  @media (max-width: 1060px) {
    align-items: flex-end;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }
`;

export const Container = styled.div`
  display: flex;
  // flex: ${props => props.size || 1};
  

  @media (max-width: 1060px) {
    display: block;
    padding: 0 80px;
    text-align: center;
  }

  @media (max-width: 960px) {
    padding: 0 25px;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    text-align: center;

    .broker-space {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const CentralizedContainer = styled(Container)`
  text-align: center;

  button {
    margin-top: 20px;
    width: 200px;
    height: 60px;
  }
  
`;

export const Content = styled.div`
  flex: ${props => props.size};
  margin: 10px;    

  a {
    text-decoration: none;
    width: 100%;
  }

  & strong {
    text-align: center;
    color: white;
  }

  button {
    margin-top: 20px;
  }

  div {
    text-align: left;
  }

  @media (max-width: 1060px) {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (max-width: 768px) {
    text-align: center;

    button {
      width: 100%;
    }
  }
`;

export const InfoContent = styled.div`
  color: white;
  

  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }

  div {
    margin-right: 10px;
    font-size: 14px;
    padding-top: 14px;

    span {
      display: block;
      padding-top: 12px;

      @media (max-width: 1060px) {
        padding: 0;
      }
    }

    p {
      margin: 7px 0 0 0;
      color: #ffd000;
      white-space: nowrap;
    }

    a {
      margin: 0 15px;
    }

    .link-email,
    .link-phone {
      margin: 0;
    }
  }
`;
