import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { ContainerFlex } from '~/components/_helpers/basic-diagramation';

export const Wrapper = styled.div`
  padding: 0 80px;
  height: 24px;
  margin: ${props =>
    props.code === null ? '19.0625rem 0 5rem 0' : '105px 0 685px 0'};
  position: relative;
  background-color: #2C3A39;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 40px;
    margin-bottom: ${props => (props.code === null ? '0' : '1206px')};
  }

  @media (max-width: 576px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Container = styled(ContainerFlex)`
  & {
    &:first {
      height: 24px;
      width: calc(100% + 40px);
      margin: 105px 0 250px -40px;
      flex-direction: row !important;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &:last-child {
      margin-top: 23.125rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

export const Step = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    display: ${props => (props.selected ? 'flex' : 'none')};
  }
`;

export const Status = styled.img`
  width: 40px;
  height: 80px;
  margin: ${props => (props.selected ? '-80px -98px 0 0' : '-80px -45px 0 0')};

  @media (max-width: 1024px) {
    display: ${props => (props.selected ? 'block' : 'none')};
    margin: ${props => (props.selected ? '-80px -188px 0 0' : '0')};
  }

  /*@media (max-width: 768px) {
    display: ${props => (props.selected ? 'block' : 'none')};
    margin: ${props => (props.selected ? '-80px -188px 0 0' : '0')};
  }*/
`;

export const Mark = styled.div`
  width: 50px;
  height: 50px;
  padding: ${props => (props.selected ? '8px' : '0')};
  margin-top: -12px;

  @media (max-width: 1024px) {
    display: ${props => (props.selected ? 'block' : 'none')};
    //margin-right: ${props => (props.selected ? '-70px' : '0')};
    //margin-right: ${props => (props.status ? '-155px' : '0')};
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    margin-right: ${props => (props.status ? '-93px' : '55px')};
  }

  &:hover > button {
    background-color: ${props => darken(0.05, '#FFC900')};
  }

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 6px #ffffff;
    background-color: #FFC900;
    transition: background-color 0.8s;
    position: relative;
    left: -7px;

    @media (max-width: 768px) {
    }

    h2 {
      margin: 0;
      width: 185px;
      position: absolute;
      left: ${props => (props.selected ? '-73px' : '-83px')};
      top: ${props => (props.selected ? '42px' : '50px')};
      color: #2C3A39;
      font-size: 20px;

      small {
        display: block;
        color: #2C3A39;
        font-weight: 300;
      }
    }
  }
`;

export const Car = styled.div`
  margin-top: -15px;
  position: relative;
  color: #2C3A39;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    bottom: 45px;
    border: none;
    color: #2C3A39;
    background: transparent;

    img {
      margin-top: 0;
      margin-right: auto;
      width: 60px !important;
      height: auto !important;
      object-fit: scale-down;
    }
  }

  .prev {
    right: 45px;

    img {
      transform: rotate(180deg);
    }
  }

  .next {
    left: 135px;

    p {
      color: #2C3A39;
      width: 5.875rem;
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      position: relative;
      right: 1.5625rem;
      bottom: 1.375rem;
    }

    img {
      height: auto !important;
    }

    @media (max-width: 1024px) {
      left: ${props => (props.status ? '180px' : '150px')};
    }
  }

  img {
    width: 157px !important;
    height: 68px !important;
    margin: ${props =>
      props.selected ? '-68px -105px 0 0' : '-68px -110px 0 0'};

    @media (max-width: 1024px) {
      display: ${props => (props.selected ? 'block' : 'none')};
    }

    @media (max-width: 768px) {
      margin: '-68px -105px 0 0';
    }
  }
`;

export const TooltipBottom = styled.div`
  width: 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 1.28125rem 0.9375rem;
  text-align: center;
  color: #1a1446;
  border-radius: 6px;
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 115px;
  right: ${props => (props.first ? '-180px' : '-130px')};

  @media (max-width: 1024px) {
    right: -130px;
  }

  &:before {
    content: '';
    left: ${props => (props.first ? 'calc(33% - 22px)' : 'calc(50% - 22px)')};
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 22px solid #f5f5f5;
    position: absolute;
    top: -22px;

    @media (max-width: 1024px) {
      left: calc(50% - 22px);
    }
  }
`;

export const TooltipBottomTitle = styled.p`
  font-size: 14px;
  text-align: center;
  color: #1a1446;
  margin: 0 0 0.3125rem 0;
`;

export const TooltipBottomPriceForMonth = styled(TooltipBottomTitle)`
  margin: 0;
`;

export const MultiplicationIcon = styled.img`
  margin: 0.3125rem 0;
`;

export const AutoControle = styled(TooltipBottomTitle)`
  margin-top: 0;
`;

export const AutoControlePrice = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #06748c;
  margin: 0;
`;

export const Divider = styled.hr`
  width: 11.5625rem;
  height: 0;
  border: 1px solid #343741;
`;

export const TooltipBottomEconomyContainer = styled.div`
  width: 11.5625rem;
  display: flex;
  margin-top: 0.3125rem;
`;

export const TooltipBottomEconomyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0.625rem;
`;

export const TooltipBottomEconomyExclamation = styled.i`
  width: 11.5625rem;
  font-size: 8px;
  text-align: left;
  color: #1a1446;
  margin-top: 0.625rem;
`;

export const TooltipBottomEconomyTitle = styled(TooltipBottomTitle)`
  margin-bottom: 0;
`;

export const TooltipBottomEconomyValue = styled(TooltipBottomTitle)`
  font-size: 36px;
  font-weight: bold;
  color: #28a3af;
  margin-top: 0.3125rem;
  margin-bottom: 0;
`;

export const TooltipTop = styled.div`
  position: absolute;
  top: -310px;
  width: 287px;
  color: #2C3A39;
  font-size: 16px;
  padding: 15px;
  border-radius: 6px;
  text-align: center;
  right: ${props => (props.first ? '-180px' : '-130px')};
  background-color: #fffbeb};
  border: 2px solid #FFC900;

  @media (max-width: 1024px) {
    right: -130px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    bottom: -43px;
    border-width: 22px;
    transform: rotate(90deg);
    border-color: transparent transparent transparent #fffbeb;
    left: ${props => (props.first ? 'calc(33% - 20px)' : 'calc(50% - 20px)')};

    @media (max-width: 1024px) {
      left: calc(50% - 20px);
    }
  }

  &:before {
    bottom: -48px;
    border-width: 24px;
    transform: rotate(90deg);
    border-color: transparent transparent transparent #FFC900;
    left: ${props => (props.first ? 'calc(33% - 22px)' : 'calc(50% - 22px)')};
    @media (max-width: 1024px) {
      left: calc(50% - 22px);
    }
  }

  p {
    font-size: 16px;
    color: #2C3A39;
    margin: 15px 0;
    text-align: left;

    span {
      float: right;
      color: #2C3A39;
    }
  }

  h4 {
    margin: 0;
    font-size: 18px;
    padding-bottom: 12px;
    border-bottom: 1px solid white;
  }

  h3 {
    margin: 0;
    text-align: left;
    color: #2C3A39;
    font-size: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #2C3A39;

    small {
      display: block;
      float: left;
      font-size: 13px;
      margin-top: 4px;
      font-weight: normal;
    }

    span {
      float: right;
      color: #2C3A39;
      font-size: 17px;
    }
  }
`;

export const ContainerArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 210px;
  transform: rotate(90deg);

  @media (max-width: 768px) {
    &:nth-child(1) {
      margin-left: 18px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
  }
`;

export const Card = styled.div`
  & {
    width: 24.0625rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.1);

    & + & {
      margin-left: 0.9375rem;

      @media (max-width: 768px) {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }

    &:nth-child(1) {
      width: 24.0625rem;
      padding: 1.16875rem 1.74375rem 1.0875rem 1.75625rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 24.0625rem;
      padding: 1.16875rem 1.5rem 0.99375rem 1.44375rem;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &:nth-child(3) {
      width: 24.0625rem;
      background-color: #f2fcfc;
      padding: 1.1875rem 1.75rem 1.4375rem 1.44375rem;

      h3 {
        margin: 0;
      }

      .last-card-values-top {
        font-size: 14px;

        @media (max-width: 768px) {
          font-size: 12px;
        }

        .discounted-month {
          color: #1a1446;
        }
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardTitleContainer = styled.div`
  & {
    display: flex;
    align-items: center;
    height: 2.371875rem;

    &:last-child {
      align-items: flex-start;
    }

    h3 {
      margin: 0 0 0 1.1875rem;
    }
  }
`;

export const CardDescriptionContainer = styled.div`
  height: 5rem;

  p {
    margin: 0.725rem 0 0 0;
    line-height: 1.25;

    .km-media-month-strong {
      color: #06748c;
    }
  }
`;

export const CardValuesContainer = styled.div`
  & {
    margin-top: 0.7875rem;
  }
`;

export const CardValuesTop = styled.div`
  font-size: 20px;

  strong {
    & {
      color: #2C3A39;

      &:nth-child(3) {
        color: #2C3A39;
      }
    }
  }

  span {
    margin: 0 0.3125rem;
    font-weight: 300;
  }
`;

export const CardValuesBottom = styled.div`
  margin-top: 0.375rem;

  strong {
    font-size: 30px;
    color: #06748c;
  }
`;

export const Clique = styled.div`
  text-align: left;
  margin-left: 10px;
`;
