import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 79px;

  img {
    margin-right: 40px;
    max-width: 100%;
  }

  @media(max-width: 1024px) {
    margin: 30px;
    flex-direction: column;
    align-items: center;

    img {
      margin-right: 0;
    }
  }
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media(max-width: 1024px) {
    margin-bottom: 30px;
  }

  h3 {
    width: 369px;
    height: 45px;
    font-size: 34.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #2C3A39;
    margin-bottom: 5px;
  }

  p {
    width: 365px;
    height: 73px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #2C3A39;
    margin-bottom: 50px;
  }

  button {
    width: 371px;
    height: 50px;
    border-radius: 4px;
    background-color: #2C3A39;
    color: #fff;
    border: 1px solid #2C3A39;
    cursor: pointer;

    span {
      width: 112px;
      height: 20px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.75px;
      text-align: center;
      color: #ffffff;
    }
  }
`;
