import React, { useState } from 'react';
import classnames from 'classnames';

import { Wrapper } from '~/components/_helpers';
import AutoControl from './AutoControl';
import { Container, Content, SideContent, ContentMessage } from './styles';

export default function About() {
  const [type, setType] = useState('auto');

  return (
    <Wrapper color='#FFC900'>
      <Container>
        <Content size={0.2}>
          <SideContent>
            <li
              id='about-auto-controle'
              onClick={() => setType('auto')}
              className={classnames({ active: type === 'auto' })}
            >
              O que é o AUTO Controle
            </li>
            <li
              id='about-tecnologia'
              onClick={() => setType('eligibility')}
              className={classnames({ active: type === 'eligibility' })}
            >
              Tecnologia
            </li>
            <li
              id='about-perfil'
              onClick={() => setType('clientProfile')}
              className={classnames({ active: type === 'clientProfile' })}
            >
              Perfil de clientes
            </li>
            <li
              id='about-liberty'
              onClick={() => setType('liberty')}
              className={classnames({ active: type === 'liberty' }, 'last')}
            >
              Yelum Seguradora
            </li>
          </SideContent>
        </Content>

        <Content size={0.8}>
          <ContentMessage>
            <AutoControl type={type} />
          </ContentMessage>
        </Content>
      </Container>
    </Wrapper>
  );
}
