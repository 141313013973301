import React from 'react';

import { Wrapper } from '~/components/_helpers';
import useWindowSize from '@rooks/use-window-size';

import carIcon from './assets/car.svg';
import checkIcon from './assets/check.svg';
import uncheckIcon from './assets/uncheck.svg';

import * as S from './styles';

const CoverageAndAssistance = () => {
  const size = useWindowSize();

  const listIncluded = [
    {
      icon: (
        <img
          src={checkIcon}
          alt='Ícone de um círculo com um símbolo de checado.'
        />
      ),
      content: 'Colisão, Incêndio e Roubo ou Furto.',
    },
    {
      icon: (
        <img
          src={checkIcon}
          alt='Ícone de um círculo com um símbolo de checado.'
        />
      ),
      content:
        'Danos materiais e corporais causados a outras pessoas ou empresas.',
    },
    {
      icon: (
        <img
          src={checkIcon}
          alt='Ícone de um círculo com um símbolo de checado.'
        />
      ),
      content: 'Assistência 24h.',
    },
  ];

  const listOptions = [
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Coberturas adicionais de vidro.',
    },
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Carro reserva.',
    },
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Indenização de despesas extraordinárias.',
    },
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Despesas de Higienização do Veículo.',
    },
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Cobertura de acessórios e equipamentos.',
    },
    {
      icon: (
        <img
          src={uncheckIcon}
          alt='Ícone de um círculo sem o símbolo de checado.'
        />
      ),
      content: 'Danos morais.',
    },
  ];

  return (
    <Wrapper color='#ffd000' mobile_mt='0px' width={size.innerWidth}>
      <S.Container>
        <S.ContainerTitle>
          <h2>Principais coberturas e assistências</h2>

          <h5>
            As coberturas do AUTO Controle são completas, assim como as do
            seguro tradicional AUTO Perfil.
          </h5>
        </S.ContainerTitle>

        <S.Content>
          <S.CarContainer>
            <img
              src={carIcon}
              alt='Ícone de um carro acidentado com o vidro perfurado por uma árvore.'
            />
          </S.CarContainer>
          <S.AutoControleIncludedContainer>
            <S.ListIncluded>
              <h6>O AUTO Controle Inclui:</h6>
              {listIncluded.map((item, index) => (
                <li key={index}>
                  {item.icon}
                  {item.content}
                </li>
              ))}
            </S.ListIncluded>
          </S.AutoControleIncludedContainer>
          <S.AutoControleOptionsContainer>
            <S.ListOptions>
              <h6>Opcionais:</h6>
              {listOptions.map((item, index) => (
                <li key={index}>
                  {item.icon}
                  {item.content}
                </li>
              ))}
            </S.ListOptions>
          </S.AutoControleOptionsContainer>
        </S.Content>
        <S.Content>
          <S.ContainerTitle>
            <h5>
              Converse com o seu corretor e descubra em detalhes todas as
              coberturas e assistências do seu AUTO Controle.
            </h5>
          </S.ContainerTitle>
        </S.Content>
      </S.Container>
    </Wrapper>
  );
};

export default CoverageAndAssistance;
