import React from 'react';

import { Wrapper } from '~/components/_helpers/';
import { Content } from './styles';
import Questions from './Questions';

export default function FAQ() {
  return (
    <Wrapper>
      <Content>
        <h1>Perguntas frequentes</h1>
        <Questions />
      </Content>
    </Wrapper>
  );
}
