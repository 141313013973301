import React, { useState } from 'react';
import { navigate } from '@reach/router';

import { generateToken } from '~/api/modules/account';
import { Input, Button } from '~/components/_helpers';
import { Container, Form, Content, Title } from './styles';
import { Wrapper } from '~/components/_helpers';
import EyeClosed from './eye_closed.svg';
import EyeOpened from './eye_opened.svg';

const BrokerAuth = () => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState('');
  
  return (
    <Wrapper>
      <Container>
        <Title>Acesso exclusivo do corretor</Title>
        <Form
          onSubmit={async e => {
            e.preventDefault();     
            if (code === '') {
              setError('Campo com preenchimento obrigatório.');
            } else {
              const { data, error } = await generateToken(code);
              if (error) {
                setError('Código de identificação inválido.');
              } else if (data && data.token) {
                localStorage.setItem('@autoControle/token', data.token);
                localStorage.setItem('showTechnologyModal', true);
                navigate('/');
              }
            }
          }}
        >
          <Content size={0.7}>
            <Input
              label="Código de identificação"
              type={visible ? 'text' : 'password'}
              value={code}
              onChange={e => {
                setError(null);
                setCode(e.target.value);
              }}
              error={error}
              icon={
                <img
                  src={visible ? EyeOpened : EyeClosed}
                  alt="Tornar senha visível ou invisível"
                  onClick={() => setVisible(!visible)}
                />
              }
              iconRight
            />
          </Content>
          <Content size={0.3} error={error}>
            <Button theme="primary" type="submit">
              Entrar
            </Button>
          </Content>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default BrokerAuth;
