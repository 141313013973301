import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 40px auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .Collapsible {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    font-size: 20px;
    color: #2C3A39;
    border-bottom: 1px solid #1a1446;
    cursor: pointer;
    width: 100%;

    .Collapsible__trigger {
      color: #2C3A39;
      font-size: 20px;
      font-weight: 500;
    }
    p {
      cursor: auto;
      strong {
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        text-align: left;
        color: #2C3A39;
      }
    }

    .Collapsible__contentInner {
      margin: 0 30px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;
