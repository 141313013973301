import styled from 'styled-components';
import { ContainerFlex, FlexContent } from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 73px;

    @media (max-width: 1000px) {
        flex-direction: column-reverse;
        > div {
            flex: 1;
        }
    }

    @media (max-width: 700px) {
        padding-top: 45px;
    }

    @media (max-width: 500px) {
        padding-top: 20px;
    }
`;

export const Content = styled(FlexContent)`
    min-height: 405px;
    color: #2C3A39;

    h1 {
        font-size: 40px;
    }

    p {
        font-size: 20px;
        width: 85%;
    }

    &:first-child {
        @media (max-width: 1000px) {
            min-height: 200px;
        }
    }
    
    @media (max-width: 700px) {
        min-height: 335px;
        
        p {
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        min-height: 225px;

        h1 {
            font-size: 28px;
        }
    }
`;

export const Background = styled.img`
    position: absolute;
    right: -12%;

    @media (max-width: 1200px) {
        right: -35%;
        top: 0;
    }

    @media (max-width: 500px) {
        height: 15rem;
    }
`;

export const Values = styled.div`
    position: absolute;
    display: flex;
    right: 0;
`;

export const Km = styled.span`
    font-weight: bold;
    font-size: 70px;
    color: white;
    background-color: #2C3A39;
    border-radius: 12px;
    padding: 5px 15px;
    margin: 5px;

    @media (max-width: 1200px) {
        font-size: 50px;
    }

    @media (max-width: 700px) {
        font-size: 40px;
    }

    @media (max-width: 500px) {
        font-size: 32px;
        padding: 8px 5px;
        margin: 2px;
    }
`;

export const Arrow = styled.img`
    margin: 0 35px 0 43px;

    @media (max-width: 700px) {
        margin: 0 3px 0 10px;
        width: 35px;
    }

    @media (max-width: 500px) {
        width: 20px;
        margin: 0 0 0 5px;
    }
`;

export const Price = styled.span`
    font-weight: bold;
    font-size: 70px;
    color: #FFC900;
    background-color: #2C3A39;
    border-radius: 12px;
    padding: 5px 15px;
    margin: 5px;
    letter-spacing: .4rem;

    @media (max-width: 1200px) {
        font-size: 50px;
    }

    @media (max-width: 700px) {
        font-size: 40px;
        letter-spacing: normal;
    }

    @media (max-width: 500px) {
        font-size: 32px;
    }
`;

export const Car = styled.img`
    position: absolute;
    bottom: 35px;
    left: 30%;

    @media (max-width: 1200px) {
        left: 32%;
    }

    @media (max-width: 1000px) {
        max-width: 90%;
        top: 25%;
        left: 5%;
    }

    @media (max-width: 700px) {
        width: 65%;
    }

    @media (max-width: 500px) {
        top: 20%;
    }
`;
