import React from 'react';

import { Content } from './styles';

const Title = ({ title, icon }) => {
  return (
    <Content>
      <span>{title}</span>
      <img src={icon} alt="Arrow icon" />
    </Content>
  );
};

export default Title;
