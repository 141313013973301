import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { handleSetCode } from '~/store/modules/code';
import useWindowSize from '@rooks/use-window-size';

import {
  getCalculator,
  postCalculator,
  postPromotionalDiscount,
} from '~/api/modules/calculator';

import { getPDF } from '~/api/modules/pdf';

import { Wrapper, Button, InputCurrency } from '~/components/_helpers';
import Tooltip from '~/components/Tooltip';

import { removeCurrencyMask, formatPrice } from '~/utils/helpers';
import TimeLine from './TimeLine';

import womanIcon from './assets/woman.svg';
import arrowRightIcon from './assets/arrow_right.svg';
import plusIcon from './assets/plus.svg';
import multiplicationIcon from './assets/multiplication.svg';
import arrowDownIcon from './assets/arrow_down.svg';
import closeIcon from './assets/close.svg';
import calculatorButtonIcon from './assets/button_arrow_right.svg';
import calculatorResultIcon from './assets/result_icon.svg';
import modalDiscountMinusIcon from './assets/minus_icon.svg';
import modalDiscountResultIcon from './assets/result_white_icon.svg';
import modalDiscountImage from './assets/modal_discount_image.png';
import AppPrintscreen from './assets/app_printscreen.png';
import Modal from '../../../components/Modal';

import * as S from './styles';

export default function Calculator({ code }) {
  window.dataLayer = window.dataLayer || [];

  const size = useWindowSize();

  const inputRef = useRef(null);
  const inputQuotationRef = useRef(null);

  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [companyValue, setCompanyValue] = useState();
  const [comparativeValue /* setComparativeValue */] = useState();
  const [values, setValues] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPDFIsOpen, setModalPDFIsOpen] = useState(false);
  const [warningNewApp, setWarningNewApp] = useState(
    !localStorage.getItem('@autoControle/showNewAppWarningModal')
  );

  const [shareLink, setShareLink] = useState('');
  const [copiedShareLink, setCopiedShareLink] = useState(false);

  const [modalPDFError, setModalPDFError] = useState(false);
  const [hashCode, setHashCode] = useState(null);
  const [calculatorKmValue, setCalculatorKmValue] = useState(400);
  const [modalDiscountIsOpen, setModalDiscountIsOpen] = useState(false);
  const [promotionalDiscount, setPromotionalDiscount] = useState(null);
  const [showEditCompanyValue, setShowEditCompanyValue] = useState(false);
  const [calculatorKmValueAppeared, setCalculatorKmValueAppeared] = useState();

  useEffect(() => {
    if (!code) return;

    (async function getDataCalculator() {
      const { status, data } = await getCalculator(code);
      if (data && status >= 200 && status <= 299) {
        setValues({
          companyValue: data.request.liberty_value,
          comparativeValue: data.request.comparative_value,
          ...data.response,
        });
      }
    })();
  }, [code]);

  async function submitCalculator() {
    if (
      !companyValue ||
      companyValue === '' ||
      removeCurrencyMask(companyValue) === 0
    ) {
      setError('Campo com preenchimento obrigatório');
    } else {
      setCalculatorKmValue(400);
      setCalculatorKmValueAppeared(null);

      const { data, status } = await postCalculator({
        km_month: 400,
        comparative_value: comparativeValue
          ? removeCurrencyMask(comparativeValue)
          : null,
        liberty_value: removeCurrencyMask(companyValue),
      });

      if (data && status >= 200 && status <= 299) {
        setValues(data);

        dispatch(handleSetCode(data.hashcode));

        const shareLink = data.hashcode;
        const [url, urn] = shareLink.split('?');

        setHashCode(urn.replace('code=', ''));
        if (url === 'http://localhost') {
          setShareLink(`${url}:3000/?${urn}`);
        } else {
          setShareLink(shareLink);
        }
      }
    }
  }

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const togglePDFModal = () => setModalPDFIsOpen(!modalPDFIsOpen);

  const toggleWarningNewApp = () => {
    localStorage.setItem('@autoControle/showNewAppWarningModal', false);
    setWarningNewApp(!warningNewApp);
  };

  const handleChangeInputCurrency = value => {
    setError(null);
    setCompanyValue(value);
  };

  const handlePDF = useCallback(async () => {
    const quotationNumber = inputQuotationRef.current.value;

    if (!quotationNumber) {
      setModalPDFError(true);
      return;
    }

    const { data } = await getPDF(hashCode, quotationNumber);

    if (!data) {
      togglePDFModal();
    }

    const file = new Blob([data], { type: 'application/pdf' });

    const urlPDF = URL.createObjectURL(file);

    window.open(urlPDF);
  }, [inputQuotationRef.current, hashCode, togglePDFModal]);

  const handleSubmitCalculatorKm = async e => {
    e.preventDefault();

    const { data } = await postCalculator({
      km_month: calculatorKmValue,
      comparative_value: comparativeValue
        ? removeCurrencyMask(comparativeValue)
        : null,
      liberty_value: removeCurrencyMask(companyValue),
    });

    setValues(data);
  };

  const handleChangeCalculatorKm = e => {
    const calculatorValueKm = String(e.target.value);

    if (
      calculatorValueKm >= 0 &&
      !calculatorValueKm.match(/\D/g) &&
      calculatorValueKm.length <= 4
    ) {
      setCalculatorKmValue(Number(calculatorValueKm));
    }
  };

  const toggleDiscountModal = () =>
    setModalDiscountIsOpen(!modalDiscountIsOpen);

  const getPromotionalDiscountData = async () => {
    const postPromotionalDiscountPayload = {
      liberty_value: companyValue
        ? removeCurrencyMask(companyValue)
        : values.libertyValue,
    };

    if (calculatorKmValueAppeared) {
      delete postPromotionalDiscountPayload.liberty_value;
      postPromotionalDiscountPayload.insurance_prize_discount =
        values.initials_values.discounted_month;
      postPromotionalDiscountPayload.value_appeared = removeCurrencyMask(
        calculatorKmValueAppeared
      );
    }

    const { data } = await postPromotionalDiscount(
      postPromotionalDiscountPayload
    );
    setPromotionalDiscount(data);
  };

  const handleChangeCalculatorKmValueAppeared = value =>
    setCalculatorKmValueAppeared(value);

  const CalculatorKmTooltipContent = (
    <S.CalculatorKmTooltipContent>
      Você sabia que <strong>mais da metade (53%)</strong>
      {` `}
      dos motoristas de SP dirigem <strong>menos de 500km por mês?</strong>
      {` `}
      Em média, essas pessoas dirigem mais ou menos 250km em 30 dias. Converse
      com o seu cliente para entender o quanto ele dirige.
    </S.CalculatorKmTooltipContent>
  );

  const InfoBottomTooltipFirstContent = (
    <S.CalculatorKmTooltipContent>
      O prêmio mínimo é equivalente a 0 km (o carro ficou estacionado o período
      de vigência inteiro)
    </S.CalculatorKmTooltipContent>
  );

  const InfoBottomTooltipSecondContent = (
    <S.CalculatorKmTooltipContent>
      O prêmio máximo é equivalente a 1.200 km. Se o cliente dirigir mais de
      1.200 km, cobraremos apenas 1.200 km
    </S.CalculatorKmTooltipContent>
  );

  return (
    <>
      {!code && (
        <Wrapper
          id='calculator'
          values={values}
          code={code}
          width={size.innerWidth}
        >
          <S.Container values={values}>
            <div>
              <S.Title>Calcule aqui o AUTO Controle</S.Title>

              <S.CalculatorContainer values={values}>
                <S.Values>
                  <p>
                    Insira o valor do prêmio Auto Perfil retirado do
                    cotador
                  </p>

                  <S.Content size={code ? 1 : 0.8}>
                    <InputCurrency
                      disabled={!!code}
                      name='liberty_value'
                      initialValue={
                        values && values.libertyValue ? values.libertyValue : 0
                      }
                      onChange={value => handleChangeInputCurrency(value)}
                      error={error}
                    />
                  </S.Content>

                  {!code && (
                    <S.Content size={0.3} error={error}>
                      <Button
                        theme='primary'
                        onClick={() => {
                          window.dataLayer.push({
                            event: 'interaction',
                            eventCategory: 'calculadora:premio',
                            eventAction: 'clicou:calcular',
                            eventValue: `input:${companyValue}`,
                          });
                          submitCalculator();
                        }}
                      >
                        Calcular
                      </Button>
                    </S.Content>
                  )}
                </S.Values>
              </S.CalculatorContainer>
            </div>

            {!values ? (
              <S.ButtonTips id='body-dicas' onClick={() => navigate('/dicas')}>
                <div>
                  <S.ButtonTipsTitle>
                    Dicas de venda: <br />
                    Como oferecer o seguro <br />
                    AUTO Controle?
                  </S.ButtonTipsTitle>

                  <Link to='/dicas'>Clique e veja nossas dicas</Link>
                </div>

                <div>
                  <img
                    src={womanIcon}
                    alt='Ícone de uma mulher com uma medalha no peito'
                  />
                </div>
              </S.ButtonTips>
            ) : (
              <S.CalculatorsContainer>
                <S.CalculatorKmContainer>
                  <S.CalculatorKmTitleContainer>
                    <S.CalculatorKmTitle>
                      Se o cliente dirigir em média por mês:
                      <S.CalculatorKmTooltipIconContainer>
                        <Tooltip content={CalculatorKmTooltipContent} />
                      </S.CalculatorKmTooltipIconContainer>
                    </S.CalculatorKmTitle>
                  </S.CalculatorKmTitleContainer>
                  <S.CalculatorKmForm onSubmit={handleSubmitCalculatorKm}>
                    <S.CalculatorInputContainer>
                      <S.CalculatorKmInput
                        value={calculatorKmValue}
                        onChange={e => handleChangeCalculatorKm(e)}
                      />
                      <S.CalculatorKmInputStaticText>
                        Km
                      </S.CalculatorKmInputStaticText>
                    </S.CalculatorInputContainer>
                    <S.CalculatorKmButton
                      type='submit'
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'interaction',
                          eventCategory: 'calculadora:km',
                          eventAction: 'clicou:calcular',
                          eventValue: `input:${calculatorKmValue}`,
                        });
                      }}
                    >
                      Calcular
                      <img
                        src={calculatorButtonIcon}
                        alt='Ícone de uma seta apontando para a direita.'
                      />
                    </S.CalculatorKmButton>
                  </S.CalculatorKmForm>
                </S.CalculatorKmContainer>
                <S.CalculatorKmResultIconContainer>
                  <img
                    src={calculatorResultIcon}
                    alt='Ícone em formato de círculo com um símbolo de igual.'
                  />
                </S.CalculatorKmResultIconContainer>
                <S.CalculatorKmResultContainer>
                  <S.CalculatorKmResultTitle>
                    <strong>Estimativa</strong>
                    Anual do AUTO Controle:
                  </S.CalculatorKmResultTitle>
                  <S.CalculatorKmResultData>
                    {formatPrice(values.quotation_value_by_km.annual_value)}
                  </S.CalculatorKmResultData>
                </S.CalculatorKmResultContainer>
              </S.CalculatorsContainer>
            )}
          </S.Container>
        </Wrapper>
      )}

      {values && (
        <Wrapper mobile_mt='0px' width={size.innerWidth}>
          {code && (
            <S.ContainerTitle>
              <h2>Sua cotação do AUTO Controle:</h2>
              <h3>Validade da cotação: 10 dias</h3>
            </S.ContainerTitle>
          )}

          <S.ContainerData>
            {values && (
              <>
                <S.ContainerDataHeader>
                  <S.ContainerDataTitle>
                    As apólices do AUTO Controle são {` `}
                    <span>mensais</span> e seu valor varia a partir do 3º mês.
                    Como funciona:
                  </S.ContainerDataTitle>

                  {!code && (
                    <S.ButtonSharingGroup>
                      <div>
                        <S.ButtonSharingLink
                          onClick={() => {
                            toggleModal();
                            window.dataLayer.push({
                              event: 'interaction',
                              eventCategory:
                                'compartilhar:abrir-modal-compartilhamento',
                              eventAction: 'clicou:compartilhar',
                              eventValue: `modal-compartilhamento-aberto:${modalIsOpen}`,
                            });
                          }}
                        >
                          Compartilhar link da simulação
                        </S.ButtonSharingLink>

                        <S.ButtonDownloadPDF
                          onClick={() => {
                            togglePDFModal();
                            window.dataLayer.push({
                              event: 'interaction',
                              eventCategory: 'baixar-pdf:abrir-modal-pdf',
                              eventAction: 'clicou:baixar-pdf',
                              eventValue: `modal-pdf-aberto:${modalPDFIsOpen}`,
                            });
                          }}
                        >
                          Baixar PDF da simulação
                        </S.ButtonDownloadPDF>
                      </div>
                    </S.ButtonSharingGroup>
                  )}
                </S.ContainerDataHeader>
                <S.Data>
                  <S.Values className='clear' style={{ marginRight: 0 }}>
                    <S.Info>
                      <h6>1ª cobrança</h6>
                      <strong>
                        {formatPrice(values.initials_values.discounted_month)}
                      </strong>
                      <span>Realizada no momento da contratação do seguro</span>

                      {!code && (
                        <S.InfoBottom
                          className='InfoBottom-first'
                          onClick={() => {
                            setModalDiscountIsOpen(true);
                            getPromotionalDiscountData();
                            window.dataLayer.push({
                              event: 'interaction',
                              eventCategory:
                                'modal-desconto-promocional:abrir-modal-desconto-promocional',
                              eventAction:
                                'clicou:abrir-modal-desconto-promocional',
                              eventValue: `modal-desconto-promocional-aberto:${modalDiscountIsOpen}`,
                            });
                          }}
                        >
                          <p>
                            Calcule o desconto promocional a ser aplicado no CPL
                          </p>
                        </S.InfoBottom>
                      )}
                    </S.Info>

                    <S.ContainerArrow className='ContainerArrow'>
                      <img
                        src={arrowRightIcon}
                        alt='Ícone de uma seta apontando para a direita'
                      />
                    </S.ContainerArrow>

                    <S.Info>
                      <h6>2ª cobrança</h6>
                      <strong>
                        {formatPrice(values.initials_values.discounted_month)}
                      </strong>
                      <span>
                        Até a 2ª cobrança, o cliente deve instalar o aplicativo
                      </span>
                    </S.Info>

                    <S.ContainerArrow>
                      <img
                        src={arrowRightIcon}
                        alt='Ícone de uma seta apontando para a direita'
                      />
                    </S.ContainerArrow>

                    <S.Info>
                      <h6>3ª cobrança em diante</h6>

                      <S.ContainerContentInfo>
                        <div>
                          <strong>
                            {formatPrice(
                              values.initials_values.monthly_payment
                            )}
                          </strong>
                          <span>Taxa fixa</span>
                        </div>

                        <S.ContainerContentInfoIcon>
                          <img
                            src={plusIcon}
                            alt='Ícone de um simbolo de adição'
                          />
                        </S.ContainerContentInfoIcon>

                        <S.Container3Value>
                          <S.Container3Group>
                            <strong>
                              {formatPrice(values.initials_values.km_day)}
                            </strong>
                            <span>Custo do km</span>
                          </S.Container3Group>

                          <S.ContainerContentInfoIcon>
                            <img
                              src={multiplicationIcon}
                              alt='Ícone de um simbolo de adição'
                            />
                          </S.ContainerContentInfoIcon>

                          <S.Container3Group>
                            <strong>KM DIRIGIDOS</strong>
                            <span>Km dirigidos no mês anterior</span>
                          </S.Container3Group>
                        </S.Container3Value>
                      </S.ContainerContentInfo>

                      {!code && (
                        <>
                          <S.InfoBottom className='InfoBottom-second'>
                            <Tooltip content={InfoBottomTooltipFirstContent} />
                            <p>
                              Prêmio mínimo =
                              <strong>
                                &nbsp;
                                {formatPrice(
                                  values.quotation_values[0].value_payment
                                )}
                              </strong>
                            </p>
                          </S.InfoBottom>
                          <S.InfoBottom className='InfoBottom-third'>
                            <Tooltip content={InfoBottomTooltipSecondContent} />
                            <p>
                              Prêmio máximo =
                              <strong>
                                &nbsp;
                                {formatPrice(
                                  values.quotation_values[8].value_payment
                                )}
                              </strong>
                            </p>
                          </S.InfoBottom>
                        </>
                      )}
                    </S.Info>
                  </S.Values>
                </S.Data>
              </>
            )}
          </S.ContainerData>

          <S.TimeLineTitle hasPadding={!code}>
            <p>
              Com base na <mark>quilometragem média percorrida por mês</mark>,
              descubra se o AUTO Controle é a melhor opção e quanto pode
              economizar:
            </p>
          </S.TimeLineTitle>
        </Wrapper>
      )}

      {values && (
        <TimeLine
          values={values.quotation_values}
          code={code}
          monthlyPayment={values.initials_values.monthly_payment}
          kmDay={values.initials_values.km_day}
          discountedMonth={values.initials_values.discounted_month}
        />
      )}

      {values && !code && (
        <Wrapper width={size.innerWidth}>
          <S.ContainerSecondArrowIcon>
            <img
              src={arrowDownIcon}
              alt='Ícone de uma seta apontando para baixo'
            />
          </S.ContainerSecondArrowIcon>

          <S.Policy mb='45px' mt='20px'>
            <S.Title>O cliente quer contratar?</S.Title>

            <a
              href='https://novomeuespacocorretor.yelumseguros.com.br/home'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                theme='primary'
                onClick={() => {
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'acesse-meu-cotador:abrir-meu-cotador',
                    eventAction: 'clicou:abrir-meu-cotador',
                    eventValue: `meu-cotador-aberto:${true}`,
                  });
                }}
              >
                Acesse o meu cotador e emita a apólice!
              </Button>
            </a>
          </S.Policy>
        </Wrapper>
      )}

      <Modal
        title='Baixe o PDF'
        toggle={togglePDFModal}
        isOpen={modalPDFIsOpen}
        onRequestClose={() => {
          togglePDFModal(false);
          window.dataLayer.push({
            event: 'interaction',
            eventCategory: 'baixar-pdf:fechar-modal-pdf',
            eventAction: 'clicou:fechar-pdf',
            eventValue: `modal-pdf-aberto:${modalPDFIsOpen}`,
          });
        }}
      >
        <S.ModalPDFContent error={modalPDFError} id='modal-download-pdf'>
          <p>Insira o número da cotação para gerar um PDF para seu cliente.</p>
          <div>
            <input
              type='text'
              placeholder='Número da cotação'
              ref={inputQuotationRef}
              onChange={() => {
                window.dataLayer.push({
                  event: 'interaction',
                  eventCategory: 'gerar-pdf:numero-cotacao',
                  eventAction: 'digitou:numero-cotacao',
                  eventValue: `numero-cotacao:${inputQuotationRef.current.value}`,
                });
              }}
            />
            <button
              type='button'
              onClick={() => {
                handlePDF();
                window.dataLayer.push({
                  event: 'interaction',
                  eventCategory: 'gerar-pdf:gerar-link-pdf',
                  eventAction: 'clicou:gerar-link-pdf',
                  eventValue: `link-pdf-aberto:${modalPDFError}`,
                });
              }}
            >
              Gerar PDF
            </button>
          </div>
        </S.ModalPDFContent>
      </Modal>

      {modalIsOpen && (
        <S.ModalSharingLinkOverlay id='modal-share-link'>
          <S.ModalSharingLink>
            <S.ModalSharingLinkHeader>
              <div>Compartilhe o link com seu cliente</div>

              <button
                type='button'
                onClick={() => {
                  setModalIsOpen(false);
                  setCopiedShareLink(false);
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'compartilhar:fechar-modal-compartilhamento',
                    eventAction: 'clicou:fechar',
                    eventValue: `modal-compartilhamento-aberto:${modalIsOpen}`,
                  });
                }}
              >
                <img
                  src={closeIcon}
                  alt='Ícone de um simbolo de X para fechar o modal'
                />
              </button>
            </S.ModalSharingLinkHeader>

            <S.ModalSharingBody>
              <S.ModalSharingBodyDescription>
                <p>
                  Copie e envie para o seu cliente o link de acesso ao site AUTO
                  Controle com as informações do seguro cotado especialmente
                  para ele:
                </p>
              </S.ModalSharingBodyDescription>

              <S.ModalSharingBodyActionContainer>
                <input
                  type='text'
                  readOnly
                  value={shareLink}
                  ref={inputRef}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: 'interaction',
                      eventCategory:
                        'compartilhar:input-modal-compartilhamento',
                      eventAction: 'focou:input-link-modal-compartilhamento',
                      eventValue: `valor-link-modal-compartilhamento:${shareLink}`,
                    });
                  }}
                />

                <CopyToClipboard
                  text={`${shareLink}/`}
                  onCopy={() => {
                    setCopiedShareLink(true);
                    window.dataLayer.push({
                      event: 'interaction',
                      eventCategory:
                        'compartilhar:copiar-link-modal-compartilhamento',
                      eventAction: 'clicou:copiar-link-modal-compartilhamento',
                      eventValue: `link-modal-compartilhamento-copiado:${copiedShareLink}`,
                    });
                  }}
                >
                  <button type='button'>
                    Copiar
                    {copiedShareLink && (
                      <S.ModalSharingTooltip>
                        <p>Link copiado!</p>
                      </S.ModalSharingTooltip>
                    )}
                  </button>
                </CopyToClipboard>
              </S.ModalSharingBodyActionContainer>
            </S.ModalSharingBody>
          </S.ModalSharingLink>
        </S.ModalSharingLinkOverlay>
      )}

      {promotionalDiscount && (
        <Modal
          className='modalDiscount'
          title='Desconto Promocional'
          toggle={toggleDiscountModal}
          isOpen={modalDiscountIsOpen}
          onRequestClose={() => {
            toggleDiscountModal(false);
            window.dataLayer.push({
              event: 'interaction',
              eventCategory:
                'modal-desconto-promocional:fechar-modal-desconto-promocional',
              eventAction: 'clicou:fechar-modal-desconto-promocional',
              eventValue: `modal-desconto-promocional-aberto:${modalDiscountIsOpen}`,
            });
          }}
        >
          <S.ModalDiscountContainer>
            <S.ModalDiscountDescription>
              Calculamos para você o valor que deve ser inserido no campo
              Desconto Promocional do Meu Cotador. Assim o cliente receberá o
              desconto de 10% sobre o 1º pagamento.
            </S.ModalDiscountDescription>
            <S.ModalDiscountCalculationContainer>
              <div>
                <p>Valor que aparece no meu cotador</p>
                {!showEditCompanyValue ? (
                  <>
                    <p>
                      {!calculatorKmValueAppeared
                        ? formatPrice(
                            Number(
                              promotionalDiscount.calculated_value_appeared
                            )
                          )
                        : `R$ ${calculatorKmValueAppeared}`}
                    </p>
                    <p
                      onClick={() => {
                        setShowEditCompanyValue(true);
                        window.dataLayer.push({
                          event: 'interaction',
                          eventCategory:
                            'modal-desconto-promocional:editar-valor-modal-desconto-promocional',
                          eventAction:
                            'clicou:editar-valor-modal-desconto-promocional',
                          eventValue: `modal-desconto-promocional-editado:${showEditCompanyValue}`,
                        });
                      }}
                    >
                      Aparece outro valor
                    </p>
                  </>
                ) : (
                  <S.CalculatorKmInputContainer>
                    <div>
                      <InputCurrency
                        name='value_appeared'
                        initialValue={Number(
                          promotionalDiscount.calculated_value_appeared
                        )}
                        onChange={value => {
                          handleChangeCalculatorKmValueAppeared(value);
                          window.dataLayer.push({
                            event: 'interaction',
                            eventCategory:
                              'modal-desconto-promocional:modal-desconto-promocional-input-meu-cotador',
                            eventAction:
                              'editou:editar-valor-modal-desconto-promocional-input-meu-cotador',
                            eventValue: `modal-desconto-promocional-valor-input-meu-cotador:${calculatorKmValueAppeared}`,
                          });
                        }}
                      />
                    </div>
                    <Button
                      theme='primary'
                      onClick={() => {
                        getPromotionalDiscountData();
                        setShowEditCompanyValue(false);
                        window.dataLayer.push({
                          event: 'interaction',
                          eventCategory:
                            'modal-desconto-promocional:modal-desconto-promocional-calcular',
                          eventAction:
                            'clicou:modal-desconto-promocional-calcular',
                          eventValue: `modal-desconto-promocional-calcular:${true}`,
                        });
                      }}
                    >
                      Calcular
                    </Button>
                  </S.CalculatorKmInputContainer>
                )}
              </div>
              <div>
                <img
                  src={modalDiscountMinusIcon}
                  alt='Ícone no formato de um círculo com um símbolo de menos.'
                />
              </div>
              <div>
                <p>Prêmio correto com desconto</p>
                <p>
                  {formatPrice(promotionalDiscount.insurance_prize_discount)}
                </p>
              </div>
              <div>
                <img
                  src={modalDiscountResultIcon}
                  alt='Ícone no formato de um círculo com um símbolo de igual.'
                />
              </div>
              <div>
                <p>Desconto Promocional</p>
                <p>
                  {formatPrice(promotionalDiscount.promotional_discount_value)}
                </p>
              </div>
            </S.ModalDiscountCalculationContainer>
            <S.ModalDiscountImageContainer>
              <img
                src={modalDiscountImage}
                alt='Imagem de um print do site Meu Cotador'
              />
            </S.ModalDiscountImageContainer>
          </S.ModalDiscountContainer>
        </Modal>
      )}

      {!code && warningNewApp && (
        <Modal
          title='Mudanças no seguro AUTO Controle'
          toggle={toggleWarningNewApp}
          isOpen={warningNewApp}
          onRequestClose={() => {
            toggleWarningNewApp(false);
            window.dataLayer.push({
              event: 'interaction',
              eventCategory: 'aviso-new-app:fechar-aviso-new-app',
              eventAction: 'clicou:aviso-new-app',
              eventValue: `modal-aviso-new-app-aberto:${warningNewApp}`,
            });
          }}
        >
          <S.ModalAvisoContent id='modal-new-app-autocontrole'>
            <p>
              À partir de agora para aderir ao seguro AUTO Controle não
              é mais necessária a instalação de um dispositivo de rastreamento
              nos veículos segurados. Basta o cliente{' '}
              <strong>baixar o aplicativo AUTO Controle</strong>,
              disponível na Apple Store e Google Play,{' '}
              <strong>
                realizar o cadastro e tirar fotos todos os meses do odômetro do
                veículo
              </strong>
              !
            </p>
            <div>
              <div>
                <img src={AppPrintscreen} alt='Printscreen do aplicativo' />
              </div>

              <div>
                <div>PRINCIPAIS MUDANÇAS</div>
                <div>
                  - Para capturar a quilometragem dirigida durante o mês, o
                  cliente deve instalar o aplicativo AUTO Controle,
                  criar uma conta e tirar fotos mensais do odômetro do veículo.
                </div>

                <div>
                  - Os clientes poderão acompanhar pelo app o histórico de
                  quilometragem, os valores pagos e a economia com o seguro por
                  quilômetro.
                </div>

                <div>
                  *Obs: Os clientes que utilizam dispositivos de rastreamento
                  como tecnologia para captura de quilometragem, permanecem
                  usando o dispositivo até que peçam a migração para foto de
                  odômetro.
                </div>
              </div>
            </div>
          </S.ModalAvisoContent>
        </Modal>
      )}
    </>
  );
}
