import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import { codeReducer } from './modules/code';
import { loaderReducer } from './modules/loader';
import { locationReducer } from './modules/location';

const rootReducer = combineReducers({
  codeReducer,
  loaderReducer,
  locationReducer,
});
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;
