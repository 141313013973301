import { createGlobalStyle } from 'styled-components';
import { globalHistory } from '@reach/router';
import './css/hamburguer.css';
import './css/react-select.css';
import './css/recharts.css';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: ${() =>
      globalHistory.location.pathname === '/broker' && 'hidden'};
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: #2f3a56;
  }

  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
`;

export default GlobalStyle;
