import { Service } from 'axios-middleware';

export default class Middlewares {
  constructor(api) {
    this.service = new Service(api);
  }

  register(middlewares) {
    this.service.register(middlewares);
  }
}
