import React, { useState, useEffect } from 'react';
import { Link as LinkRouter, navigate } from '@reach/router';
import { Link } from 'react-scroll';

import Button from '~/components/_helpers/Button';

import LogoCompany from './assets/logo-autocontrole.svg';

import {
  ContentHeader,
  Container,
  Logo,
  Nav,
  ButtonCalculator,
} from './styles';

export default function Header() {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState();

  function handleOpen() {
    setOpen(!open);
  }

  useEffect(() => {
    const _code = new URLSearchParams(window.location.search).get('code');
    if (!_code) return;
    setCode(_code);
  }, []);

  const NavLink = props => (
    <LinkRouter
      {...props}
      getProps={({ isCurrent }) => ({
        className: isCurrent ? 'active' : '',
      })}
    />
  );

  return (
    <ContentHeader>
      <Container>
        <Logo to={code ? `/?code=${code}` : '/'}>
          <img src={LogoCompany} width={"300px"}/>
        </Logo>

        {!code && (
          <Nav>
            <NavLink id='header-auto-controle' to='/auto-controle'>
              AUTO Controle
            </NavLink>
            <NavLink id='header-dicas' to='/dicas'>
              Dicas
            </NavLink>
            {/* <NavLink id='header-material-apoio' to='/material-apoio'>
              Material de Apoio
            </NavLink> */}
            <NavLink id='header-faq' to='/faq'>
              FAQ
            </NavLink>
          </Nav>
        )}

        {!code && (
          <ButtonCalculator>
            <Link
              activeClass='active'
              to='calculator'
              spy
              smooth
              offset={-70}
              duration={800}
            >
              <Button
                theme='primary'
                onClick={() => {
                  navigate('/');
                  handleOpen();
                }}
              >
                Calcule o AUTO Controle
              </Button>
            </Link>
          </ButtonCalculator>
        )}
      </Container>
    </ContentHeader>
  );
}
