export const setTheme = ({ theme }) => {
  switch (theme) {
    case 'primary':
      return {
        color: '#2C3A39',
        background: '#FFC900',
        border: 'solid 2px #FFC900',
      };
    case 'secondary':
      return {
        color: '#FFC900',
        background: '#2C3A39',
        border: 'solid 2px #2C3A39',
      };
    case 'ghost':
      return {
        color: '#1e194d',
        background: 'transparent',
        border: 'solid 2px #2f3a56',
      };
    default:
      return {
        color: '#2C3A39',
        background: '#FFC900',
        border: 'solid 2px #FFC900',
      };
  }
};

export const setSize = ({ size }) => {
  switch (size) {
    case 'mini':
      return {
        padding: '10px 16px',
        fontSize: '10px',
      };
    case 'tiny':
      return {
        padding: '12px 20px',
        fontSize: '12px',
      };
    case 'small':
      return {
        padding: '12px 20px',
        fontSize: '15px',
      };
    case 'large':
      return {
        padding: '12px 23px',
        fontSize: '16px',
      };
    case 'big':
      return {
        padding: '12px 26px',
        fontSize: '18px',
      };
    case 'huge':
      return {
        padding: '15px 30px',
        fontSize: '20px',
      };
    case 'massive':
      return {
        padding: '15px 34px',
        fontSize: '22px',
      };
    default:
      return {
        padding: '12px 35px',
        fontSize: '14px',
      };
  }
};
