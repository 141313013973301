import React from 'react';
import { Link, navigate } from '@reach/router';
import useWindowSize from '@rooks/use-window-size';

import arrowRightIcon from './assets/arrowRight.svg';

import { Content, Wrapper, Container, Button } from './styles';

const buttonContent = [
  {
    title: 'Quero mais informações sobre o que é o AUTO Controle',
    link: '/auto-controle',
    testId: 'body-auto-controle',
  }, 
  { title: 'Acessar Perguntas Frequentes', link: '/faq', testId: 'body-faq' },
];

// {
//   title: 'Quero acessar os materiais de apoio do AUTO Controle',
//   link: '/material-apoio',
//   testId: 'body-material-apoio',
// },

export default function Buttons() {
  const size = useWindowSize();

  return (
    <Wrapper width={size.innerWidth}>
      {buttonContent.map((content, index) => (
        <Container key={index} id={content.testId}>
          <Content>
            <Button type='button' onClick={() => navigate(content.link)}>
              <img
                src={arrowRightIcon}
                alt='Ícone de uma seta apontando para a direita'
              />
              <Link to={content.link}>{content.title}</Link>
            </Button>
          </Content>
        </Container>
      ))}
    </Wrapper>
  );
}
