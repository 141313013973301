import constant from '~/api/constant';
import api from '~/api';

const { ACCOUNT } = constant;

export const generateToken = (code) => {
  console.log(ACCOUNT.POST_TOKEN_URL)
  return api.post(ACCOUNT.POST_TOKEN_URL, {
    code_access: code,
  });
};