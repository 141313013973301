import styled from 'styled-components';

import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      flex: 1;
    }
  }

  @media (max-width: 700px) {
    padding-top: 45px;
  }

  @media (max-width: 500px) {
    padding-top: 20px;
    flex-direction: column;
  }
`;

export const Content = styled(FlexContent)`
  min-height: 370px;
  margin: ${props => (props.questions ? '25px' : 'auto')};

  h1 {
    color: #2C3A39;
    font-size: 40px;
    font-weight: 500;
  }

  p {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    min-height: 335px;

    p {
      width: 100%;
    }

    button {
      margin: auto;
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    min-height: 225px;

    h1 {
      font-size: 28px;
    }
  }
`;

export const ContentImg = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 40px auto;

  @media (max-width: 500px) {
    height: 15rem;
  }

  @media (max-width: 768px) {
    height: 20rem;
  }
`;
