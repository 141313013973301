import React from 'react';
import { Link } from '@reach/router';

import PageNotFound from './img_error_404.svg';
import { Container, TextInfo } from './styles';

export const NotFoundPage = () => (
  <Container>
    <img src={PageNotFound} alt="404" />
    <TextInfo>
      <h3>Página não encontrada!</h3>
      <p>
        Desculpe, mas a página que você está procurando pode ter sido removida
        ou renomeada, ou ainda o link que você acessou pode estar incorreto.
      </p>
      <Link to="/">
        <button type="button">
          <span>Voltar ao início</span>
        </button>
      </Link>
    </TextInfo>
  </Container>
);

export const InternalServerErrorPage = () => <div>internal</div>;
