import constant from '~/api/constant';
import api from '~/api';

const { PDF } = constant;

export const getPDF = (hashCode, quotationNumber) => {
  return api.post(
    `${PDF.GET_PDF_URL}${hashCode}/`,
    { quotation_id: quotationNumber },
    { responseType: 'blob' }
  );
};
