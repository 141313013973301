import styled from 'styled-components';
import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const Container = styled(ContainerFlex)`
  align-items: center;
  padding: 74px 0;
`;

export const Content = styled(FlexContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  color: #2C3A39;

  img {
    height: 100%;
  }

  h1 {
    width: 100%;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
  }

  p {
    line-height: 1.63;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    h1 {
      text-align: center;
      margin-top: 30px;
      font-size: 26px;
    }

    p {
      text-align: center;
    }
  }
`;
