import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  max-width: 65em;

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

export const ContentItem = styled(Content)`
  flex-direction: column;
  justify-content: center;

  p {
    line-height: 1.5;
  }

  div {
    margin-top: 0.5em;

    a {      
      color: #1a1446;
      font-style: normal;
    }
  }
`;

export const AutoContent = styled(Content)`
  > img {
    margin-top: -1em;
    margin-right: -2em;
    max-width: 100%;
  }
`;

export const TechnologyContent = styled(Content)`
  > img {
    margin-top: -1em;
    margin-bottom: -1em;
    max-width: 100%;
  }

  .technology__content {
    margin-right: 16px;
    margin-bottom: 16px;
    span {
      font-style: italic;
    }
  }
`;

export const CompanyContent = styled(Content)`
  > img {
    margin-top: -3em;
  }

  p {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  }
`;

export const ClientProfileContent = styled(CompanyContent)`
  .clientProfile__content {
    ul {
      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    }

    p {
      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
  }
  @media (max-width: 465px) {
    ul {
      margin: auto;
    }
  }
  .clientProfile__content {
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;
