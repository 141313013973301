import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  cursor: pointer;
  user-select: none;
  z-index: 99;
  right: 20px;
  position: absolute;
  border-radius: 5px;
  background-color: #ffd000;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  width: ${props => props.open ? '240px' : 'auto'};
  bottom: 0;
  padding: 12px 20px;
  color: #2C3A39;

  &:hover {
    background-color: ${darken(0.05, '#ffd000')};
  }

  div {
    display: flex;
    font-size: 14px;

    strong {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  span {
    p {
      font-size: 14px;
    }

    a {
      text-decoration: none;
    }

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;
