import React from 'react';
import { Container } from './styles';
import loading from './loading_100x100.gif';

export default function Loading() {
  return (
    <Container>
      <img src={loading} alt="loading" />
    </Container>
  );
}
