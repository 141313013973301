import styled from 'styled-components';
import { darken } from 'polished';
import {
  ContainerFlex,
  FlexContent,
} from '~/components/_helpers/basic-diagramation';

export const WrapperSteps = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  margin-top: 50px;

  @media (max-width: 1000px) {
    font-size: 28px;
  }
`;

export const Container = styled(ContainerFlex)`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  padding: 40px 0 70px;

  hr {
    position: absolute;
    top: calc(
      40px + 41px - 1.5px
    ); /* padding-top + circle radius - hr height/2 */
    width: 100vw;
    margin: 0 0 0 -15px;
    border: 3px solid #343741;
    background-color: #343741;

    @media (max-width: 576px) {
      width: 104vw;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: row !important;

    hr {
      top: calc(
        40px + 21px - 1px
      ); /* padding-top + circle radius - hr height/2 */
      border-width: 2px;
    }
  }

  @media (max-width: 375px) {
    padding: 6px 0 110px;

    hr {
      top: calc(
        6px + 21px - 1px
      ); /* padding-top + circle radius - hr height/2 */
    }
  }
`;

export const Content = styled(FlexContent)`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    font-size: 40px;
    background-color: ${props => (props.selected ? '#06748c' : '#28a3af')};
    font-weight: bold;
    color: white;
    border-radius: 50%;
    width: 82px;
    height: 82px;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${props =>
        props.selected ? '#06748c' : darken(0.05, '#28a3af')};
    }

    @media (max-width: 1000px) {
      width: 42px;
      height: 42px;
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 16px 0;

    @media (max-width: 1000px) {
      display: ${props => (props.selected ? 'block' : 'none')};
      position: absolute;
      width: 100%;
      top: 82px;
      text-align: center;
      left: 0;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 15px;
    background-color: white;
    padding: 23px;
    border-radius: 6px;
    font-size: 16px;
    color: #1a1446;
    text-align: center;

    &:before {
      content: '';
      left: calc(50% - 22px);
      width: 0;
      height: 0;
      border-left: 22px solid transparent;
      border-right: 22px solid transparent;
      border-bottom: 22px solid white;
      position: absolute;
      top: -22px;
    }

    @media (max-width: 1000px) {
      width: 100%;
      position: absolute;
      left: 0;
      top: 250px;
    }

    @media (max-width: 768px) {
      top: 150px;
    }

    @media (max-width: 576px) {
      top: 130px;
    }
  }

  @media (max-width: 1000px) {
    padding-bottom: 120px;
  }

  button {
    font-size: 16px;
    text-decoration: underline;
    background: #fff;
    color: #28a3af;
    border: none;
    margin-top: 0.375rem;
    cursor: pointer;
  }
`;

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.375rem 4.25rem 3.75rem 2.125rem;

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 18px;
  }
`;

export const ModalContentText = styled.div`
  width: 41.25rem;
  height: 19.0625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100%;
    height: 28.25rem;
  }

  p {
    width: 100%;
    height: 100%;
    margin: 0;

    @media (max-width: 576px) {
      object-fit: contain;

      & + p {
        margin-top: 1.5rem;
      }
    }
  }
`;

export const ModalContentImage = styled.div`
  width: 11.5625rem;
  height: 18.0625rem;

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 576px) {
      object-fit: contain;
    }
  }
`;
