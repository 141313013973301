import styled from 'styled-components';

export const ContentInput = styled.label`
  color: #2C3A39;
  font-size: 16px;
  width: 100%;
  display: block;
  margin-bottom: 25px;

  img {
    cursor: pointer;
  }

  input {
    width: 100%;
    padding: 11px;
    margin-right: 3px;
    font-size: 16px;
    text-align: left;
    border: none;
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
    border-radius: 10px;
    background-color: transparent;

    &::placeholder {
      color: #919191;
    }

    &:focus {
      border-color: rgba(254, 218, 2, 0.5);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px  white inset;
    }
  }

  div {
    margin: 6px 0;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: ${props =>
      props.hasError ? '#fff4f5' : '#fff'};
    border: ${props =>
      props.hasError ? 'solid 1px #e92243' : 'solid 1px #e6e6e6'};

    span {
      margin-right: 10px;
    }
  }

  > span {
    color: #d32f2f;
    font-size: 12px;
    &:before {
      content: '!';
      border-radius: 50%;
      padding: 0 5px;
      margin-right: 5px;
      border: solid 1px #d32f2f;
    }
  }
`;
