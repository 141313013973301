import React, { useEffect } from 'react';
import { Router } from '@reach/router';

import Layout from '~/components/Layout';
import Navigation from '~/components/_helpers/Navigation';

import LandingPage from '~/pages/LandingPage';
import { NotFoundPage } from '~/pages/NotFoundPage';
import BrokerAuth from '~/pages/BrokerAuth';
import Faq from '~/pages/FAQ';
import AutoControle from '~/pages/AutoControle';
import Support from '~/pages/Support';
import Tips from '~/pages/Tips';

const App = () => {
  const isIE = false || !!document.documentMode;

  useEffect(() => {
    if (isIE) {
      alert(
        `Este navegador não é suportado por este site.\n
        \nPor favor, utilize um navegador mais moderno.`
      );
    }
  }, [isIE]);

  return (
    <Layout>
      <Router>
        <Navigation path='/'>
          <LandingPage path='/' />
          <AutoControle path='/auto-controle' />
          <NotFoundPage default />
          <BrokerAuth path='/broker' />
          <Faq path='/faq' />
          <Support path='material-apoio' />
          <Tips path='/dicas' />
        </Navigation>
      </Router>
    </Layout>
  );
};

export default App;
