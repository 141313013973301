import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #06748c;
  padding: 0.90625rem 0 2.3125rem 0.5rem;

  @media (max-width: 576px) {
    padding: 0.90625rem 0 0.5625rem 0.5rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.61875rem;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1.11875rem;
  }

  p {
    margin: 0;
    font-size: 11px;
    color: #fff;

    &:nth-child(2) {
      @media (max-width: 576px) {
        margin-top: 0.4375rem;
      }
    }
  }
`;
