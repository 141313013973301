import limit from './assets/limit.svg';
import stagnate from './assets/stagnate.svg';
import warning from './assets/warning.svg';

export default [
  {
    selected: false,
    status: {
      src: stagnate,
      alt: 'estagnado',
    }
  },
  {
    selected: false,
  },
  {
    selected: false,
  },
  {
    selected: false,
  },
  {
    selected: true,
  },
  {
    selected: false,
  },
  {
    selected: false,
  },
  {
    selected: false,
    status: {
        src: warning,
        alt: 'em alerta',
    }
  },
  {
    selected: false,
    limit: true,
    status: {
      src: limit,
      alt: 'no limite',
    }
  },
];
