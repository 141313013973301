import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Button } from '~/components/_helpers';

import emoji from './emoji.svg';
import { Container, Content } from './styles';

export default function Refused({ data }) {
  const { detail } = data;
  let title = null;
  let message = null;

  if (detail && detail[0]) {
    const _detail = detail[0];
    title = _detail.title || '';

    if (typeof _detail === 'string') {
      message = _detail;
    } else if (_detail.message) {
      message = _detail.message;
    } else {
      message = 'Ocorreu algum tipo de problema.';
    }
  }

  return (
    <Container>
      <Content>
        <h2>{title}</h2>
        <img src={emoji} alt="emoji" />
        <p>{message}</p>
        <Button
          type="button"
          theme="primary"
          onClick={async () => {
            navigate('/');
            localStorage.removeItem('@autoControle/token');
            window.location.reload();
          }}
        >
          Entendi
        </Button>
      </Content>
    </Container>
  );
}

Refused.propTypes = {
  data: PropTypes.shape({
    detail: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
