import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const Content = styled.div`
  max-width: 464px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h2 {
    font-size: 25px;
  }

  img {
    margin: 15px 0;
  }

  p {
    font-size: 18px;
    margin-bottom: 26px;
  }
`;
