import styled from 'styled-components';
import { FlexContent } from '~/components/_helpers/basic-diagramation';

export const Container = styled(FlexContent)`
  @media (max-width: 576px) {
    margin-top: 82px;
  }
`;

export const TitlePage = styled.h1`
  color: #1a1446;
  font-size: 40px;
  font-weight: normal;
  margin-top: 82px;
  margin-bottom: 21px;
`;

export const Content = styled(FlexContent)``;

export const TitleContent = styled.h1`
  color: #06748c;
  font-size: 40px;
  font-weight: bold;
`;

export const TextContent = styled.p`
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const TextBoldContent = styled.p`
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const VideoContainer = styled(FlexContent)`
  display: flex;
  flex-flow: row wrap;
`;

export const VideoContent = styled(FlexContent)`
  margin-bottom: 30px;
`;

export const Video = styled.iframe`
  width: 590px;
  height: 320px;

  @media (max-width: 576px) {
    width: 59%;
  }
`;

export const TextVideoContent = styled(FlexContent)`
  background-color: #f5f5f5;
  width: 590px;
  margin-top: -4px;
  padding: 21px 24px 15px 24px;
`;

export const TextVideo = styled.p`
  color: #1a1446;
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
`;

export const InformativeContent = styled(FlexContent)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 80px 16px;

  @media (max-width: 576px) {
    grid-template-columns: auto;
  }
`;

export const InformativeCard = styled(FlexContent)`
  width: 287px;
  height: 510px;
  border: solid 1px #707070;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InformativeImg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const TitleHelp = styled.h1`
  color: #06748c;
  font-size: 40px;
  font-weight: normal;
  margin-top: 89px;
  margin-bottom: 0;
`;

export const HelpContent = styled(FlexContent)`
  width: 521px;
  height: 86px;
`;

export const TextHelp = styled.p`
  color: #1a1446;
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    color: #06748c;
  }
`;

export const TextInformativeContent = styled(FlexContent)`
  background-color: #f5f5f5;
  width: calc(100% + 2px);
  margin-top: -3px;
  margin-left: -1px;
  padding: 21px 24px 15px 24px;
`;

export const TextInformative = styled.p`
  color: #1a1446;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  text-align: center;
`;

export const AppContentContainer = styled(Content)`
  margin: 120px 0 30px;
`;

export const AppContent2 = styled(FlexContent)``;

export const AppImage = styled.img`
  width: 100%;
  max-width: 169px;
  height: 100%;
`;

export const AppContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-bewteen;

  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      padding-right: 50px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    > div {
      &:first-child {
        width: 100%;
        padding: 0 50px 50px 0;
      }
    }
  }
`;

export const StoreImage = styled.img`
  width: 100%;
  max-width: 236px;
  height: 100%;
  cursor: pointer;
`;

export const AppLink = styled.a``;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
`;

export const StoreItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > a:nth-child(2) {
    margin-bottom: 22px;
  }
`;
