import React from 'react';

import LogoAutoControle from './assets/logo-autocontrole.svg';

import * as S from './styles';
// FooterSecondary
const FooterSecondary = () => (
  <S.Container>
    <S.LogoContainer>
      <img
        src={LogoAutoControle}        
      />
    </S.LogoContainer>
    <S.CopyContainer>
      <p>© 2024 Yelum Seguradora. Todos os direitos reservados.</p>
      <p>Yelum Seguros S.A. CNPJ 61.550.141/0001-72 - Cód. SUSEP: 518-5.</p>
      <p>Processo 15414.100331/2004-96 e 15414.901089/2015-23</p>
    </S.CopyContainer>
  </S.Container>
);

export default FooterSecondary;
