import styled from 'styled-components';

export const ContentHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 155px;
  background-color: #2C3A39;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80%;
  }
`;
