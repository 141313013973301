import styled from 'styled-components';

export const WrapperComponent = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 0 0.25rem;
`;

export const ContainerBlock = styled.div`
  width: 100%;
  display: block;
  position: relative;
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: ${props => (props.mobile ? props.mobile : 'column')};
  }

  @media (min-width: 768px) {
    flex-direction: ${props => (props.tablet ? props.tablet : 'row')};
  }

  @media (min-width: 1000px) {
    flex-direction: ${props => (props.desktop ? props.desktop : 'row')};
  }
`;

export const FlexContent = styled.div`
  flex: ${props => (props.size ? props.size : 1)};
  display: block;
`;
