/* eslint no-mixed-operators: "off" */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalHistory } from '@reach/router';
import PropTypes from 'prop-types';

import {
  handleSetMiddlewareLoader,
  handleSetStatusLoader,
} from '~/store/modules/loader';
import Middlewares from '~/middlewares';
import LoaderMiddleware from '~/middlewares/loader';
import TokenMiddleware from '~/middlewares/token';

import api from '~/api';

import HeaderTransparent from './HeaderTransparent';
import Error from './Error';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import LoginHeader from './LoginHeader';
import Refused from './Refused';
import TalkToMe from './TalkToMe';

import GlobalStyle from '~/global-styled';
import { Container, Content } from './styles';

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const { loader, middleware } = useSelector(state => state.loaderReducer);
  const { location } = globalHistory;
  const [code, setCode] = useState();
  const [codeInUrl, setCodeInUrl] = useState(false);
  const [brokerInUrl, setBrokerInUrl] = useState(false);

  useEffect(() => {
    const _code = new URLSearchParams(window.location.search).get('code');

    if (!_code) setCode(false);

    setCode(_code);

    new Middlewares(api).register([
      new LoaderMiddleware(params => {
        dispatch(handleSetStatusLoader(params));
      }),
      new TokenMiddleware(),
    ]);

    dispatch(handleSetMiddlewareLoader(true));

    globalHistory.listen(() => {
      dispatch(
        handleSetStatusLoader({
          children: true,
          loading: false,
          error: false,
          refused: false,
        })
      );
    });

    setCodeInUrl(location.search.includes('?code'));
    setBrokerInUrl(location.pathname.includes('/broker'));
  }, [dispatch, location]);

  return (
    <Container brokerInUrl={brokerInUrl}>
      {location && location.pathname === '/broker' && <LoginHeader />}

      {location && location.pathname !== '/broker' && !codeInUrl && <Header />}

      {codeInUrl && <HeaderTransparent />}

      {middleware && (
        <Content codeInUrl={codeInUrl} brokerInUrl={brokerInUrl}>
          {loader.error && <Error />}
          {loader.children && children}
          {loader.loading && <Loading />}
          {loader.refused && <Refused data={loader.refused} />}
        </Content>
      )}

      {!code && location.pathname !== '/' && <Footer />}

      <GlobalStyle />
    </Container>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
