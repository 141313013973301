import React from 'react';
import RCTooltip from 'rc-tooltip';
import PropTypes from 'prop-types';

import 'rc-tooltip/assets/bootstrap_white.css';
import tooltipIcon from './tooltip.svg';
import './styles.css';

export default function Tooltip({ content }) {
  return (
    <RCTooltip
      destroyTooltipOnHide
      placement="top"
      trigger={['hover']}
      overlay={content}
    >
      <img src={tooltipIcon} alt="icone de informação" />
    </RCTooltip>
  );
}

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
};
