import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import Banner from './Banner';
import About from './About';
import Calculator from './Calculator';
import Steps from './Steps';
import CoverageAndAssistance from './CoverageAndAssistance';
import FAQ from './FAQ';
import Footer from 'components/Layout/Footer';
import FooterSecondary from 'components/Layout/FooterSecondary';

export default function LandingPage() {
  const [urlCode, setUrlCode] = useState();

  useEffect(() => {
    const _code = new URLSearchParams(window.location.search).get('code');

    if (!_code && !localStorage.getItem('@autoControle/token')) {
      navigate('/broker');
    } else {
      setUrlCode(_code);
    }
  }, []);

  return (
    <>
      {urlCode && <Banner />}
      {urlCode && <About />}
      {<Calculator code={urlCode} />}
      {urlCode && <CoverageAndAssistance />}
      {urlCode && <Steps code={urlCode} />}
      {!urlCode && <FAQ />}
      {urlCode ? <FooterSecondary /> : <Footer />}
    </>
  );
}
