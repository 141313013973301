import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  color: #2C3A39;
  margin-top: 56px;
  margin-bottom: 0px;

  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const SubTitle = styled.h2`
  font-size: 40px;
  color: #2C3A39;
  margin-top: 21px;
  margin-bottom: 0px;

  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const TipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 25px;

  p {
    font-size: 20px;
    margin-left: 76px;
    line-height: 1.4;

    @media (max-width: 576px) {
      margin-right: 76px;
      text-align: center;
    }

    & + p {
      line-height: 0;

      @media (max-width: 576px) {
        line-height: 1.4;
      }
    }

    & + strong {
      font-size: 20px;
      color: #1a1446;
      margin-left: 76px;

      @media (max-width: 576px) {
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        line-height: 1.4;
      }
    }
  }
`;

export const FirstTip = styled.div`
  flex: 0.5;
  color: #2C3A39;

  @media (max-width: 576px) {
    flex: 1;
    text-align: center;
    margin-bottom: 10px;
  }

  strong {    
    color: #2C3A39;
  }
`;

export const SecondTip = styled.div`
  flex: 0.5;

  @media (max-width: 576px) {
    flex: 1;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const FirstTipTitle = styled.div`
  display: flex;
  align-items: center;  
  color: #2C3A39;
  

  @media (max-width: 576px) {
    width: 100%;
    text-align: center;
  }

  > div {
    min-width: 58px;
    max-width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bold;
    color: #FFC900;
    background-color: #2C3A39;
    z-index: 1;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 100;
    color: #2C3A39;
    padding-left: 45px;
    padding-right: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #f5f5f5;
    border-radius: 5px;
    position: relative;
    right: 29px;

    @media (max-width: 576px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const SecondTipTitle = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 576px) {
    text-align: center;
  }

  > div {
    min-width: 58px;
    max-width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bold;
    color: #FFC900;
    background-color: #2C3A39;
    z-index: 1;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 100;
    color: #2C3A39;
    padding-left: 45px;
    padding-right: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #f5f5f5;
    position: relative;
    top: 20px;
    right: 29px;

    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }
`;

export const FirstTipList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 20px;
  margin-left: 37px;

  @media (max-width: 576px) {
    margin-left: 0;
    padding-left: 0;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #2C3A39;
    position: relative;

    @media (max-width: 576px) {
      max-width: 115px;
      flex-direction: column;
      margin: 0 auto;
    }

    & + li {
      margin-top: 10px;
    }

    img {
      margin-left: 8px;

      &:nth-child(1),
      &:nth-child(2) {
        cursor: pointer;
      }

      @media (max-width: 576px) {
        margin-top: 5px;
        margin-left: 0;
        margin-bottom: 5px;
      }
    }

    &:nth-child(1)::after,
    &:nth-child(2)::after {
      content: '';
      border: dotted;
      border-color: #1a1446;
      margin-left: 10px;
      transition: all 0.2s;
    }

    &:nth-child(1)::after {
      visibility: ${props =>
        props.tooltipJobIsVisible ? 'visible' : 'hidden'};
      width: calc(${props => props.widthTooltipJob}px + 60px);
    }

    &:nth-child(2)::after {
      visibility: ${props =>
        props.tooltipStudyIsVisible ? 'visible' : 'hidden'};
      width: calc(${props => props.widthTooltipStudy}px + 60px);
    }
  }
`;

export const SecondTipList = styled.ul`
  list-style: none;
  margin-left: 37px;
  margin-top: 36px;

  @media (max-width: 576px) {
    margin-left: 0;
    text-align: center;
    padding-left: 0;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #2C3A39;
    position: relative;

    @media (max-width: 576px) {
      max-width: 200px;
      flex-direction: column;
      margin: 0 auto;
    }

    & + li {
      margin-top: 10px;
    }

    img {
      margin-left: 8px;

      @media (max-width: 576px) {
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 10px;
      }
    }

    &:nth-child(2)::after {
      content: '';
      visibility: ${props =>
        props.tooltipUpTo500KmIsVisible ? 'visible' : 'hidden'};
      width: calc(${props => props.widthTooltipUpTo500Km}px + 60px);
      border: dotted;
      border-color: #1a1446;
      margin-left: 10px;
      transition: all 0.2s;
    }
  }
`;

export const TooltipJob = styled.div`
  width: 202px;
  visibility: ${props => (props.tooltipJobIsVisible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  background-color: #ffffff;
  border: dotted;
  border-color: #1a1446;
  color: #1a1446;
  padding: 17px 15px;
  position: absolute;
  top: -15px;
  right: 0;
  z-index: 1;
  transition: all 0.2s;
`;

export const TooltipStudy = styled(TooltipJob)`
  visibility: ${props => (props.tooltipStudyIsVisible ? 'visible' : 'hidden')};
`;

export const TooltipUpTo500Km = styled(TooltipJob)`
  visibility: ${props =>
    props.tooltipUpTo500KmIsVisible ? 'visible' : 'hidden'};
  right: -100px;
`;

export const DoubtContainer = styled.div`
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  border: 2px solid #1a1446;
  border-radius: 5px;
  padding: 14px 0 25px 29px;
  position: relative;
  margin-top: 35px;

  @media (max-width: 576px) {
    width: 100%;
    align-items: center;
    padding: 14px 25px;
    margin-top: 0;
  }

  img {
    position: absolute;
    right: -113px;
    top: calc(50% - 117px);

    @media (max-width: 576px) {
      display: none;
    }
  }
`;

export const DoubtTitle = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  h3 {
    font-size: 28px;
    font-weight: 100;
    color: #2C3A39;
    margin: 0;

    @media (max-width: 576px) {
      text-align: center;
    }

    &:last-child {
      margin-left: 9px;
      background-color: #FFC900;
      padding: 7px 13px 8px 8px;
      border-radius: 5px;
    }
  }
`;

export const DoubtList = styled.ul`
  list-style: none;
  font-size: 20px;
  color: #2C3A39;
  margin: 0;
  margin-bottom: 0;

  @media (max-width: 576px) {
    text-align: center;
    padding-left: 0;
  }

  li {
    @media (max-width: 576px) {
      & + li {
        margin-top: 5px;
      }
    }
  }

  p {
    margin: 0;
    margin-top: 8px;

    @media (max-width: 576px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const QuotationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const QuotationTipsContainer = styled(TipsContainer)`
  flex-direction: column;

  p {
    @media (max-width: 576px) {
      margin-right: 76px;
      text-align: center;

      &:last-child {
        flex-direction: column;
        line-height: 1.4;
      }
    }
  }
`;

export const QuotationTitle = styled(SubTitle)`
  margin: 0;
`;

export const ThirdTip = styled(FirstTip)`
  flex: 1;
  margin-bottom: 10px;
  color: #2C3A39;

  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const ThirdTipTitle = styled(FirstTipTitle)``;

export const FourthTip = styled.div`
  margin-top: 30px; 

  p {
    line-height: 1.4;
    color: #2C3A39;
    margin-bottom: 5px;.

    strong {
      color: #2C3A39;
    }
  }

  
`;

export const ContainerToDivideContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  width: 60%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ContentRight = styled.div`
  /* width: 40%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 576px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const FourthTipTitle = styled(ThirdTipTitle)``;

export const FifthTip = styled(FourthTip)`
  margin-top: 30px;

  @media (max-width: 576px) {
    flex: 1;
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    line-height: 1.4;
    margin-bottom: 5px;

    &:last-child {
      margin-top: 0;
      line-height: 1.4;
    }
  }

  strong {
    @media (max-width: 576px) {
      text-align: center;
      margin-top: 5px;
      margin-left: 0;
    }
  }
`;

export const FifthTipTitle = styled(FourthTipTitle)``;

export const EmissionContainer = styled(TipsContainer)`
  flex-direction: column;
`;

export const SixthTip = styled(FifthTip)``;

export const SixthTipTitle = styled(FifthTipTitle)``;

export const SeventhTip = styled(SixthTip)``;

export const SeventhTipTitle = styled(SixthTipTitle)``;

export const ContentBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #1a1446;
  border-radius: 5px;
  margin-left: 76px;
  position: relative;
  margin-top: 22px;

  @media (max-width: 576px) {
    flex-direction: column;
    margin-left: 0;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 21px 35px;

    @media (max-width: 576px) {
      align-items: center;
    }

    &:nth-child(1) {
      flex: 0.2;
    }

    &:nth-child(2) {
      flex: 0.4;

      ul {
        margin: 0;
        font-size: 20px;
        color: #2C3A39;

        @media (max-width: 576px) {
          list-style: none;
          padding-left: 0;
        }

        h4 {
          margin: 0;
          margin-bottom: 5px;
        }

        li {
          margin-left: 21px;
          line-height: 1.4;
          font-weight: 300;
        }
      }
    }

    &:nth-child(3) {
      flex: 0.4;
      background-color: #f5f5f5;
      font-size: 20px;
      color: #2C3A39;
      padding: 49px 35px;

      @media (max-width: 576px) {
        padding: 49px 35px 0;
      }

      ul {
        margin: 0;
        position: relative;
        bottom: 30px;

        @media (max-width: 576px) {
          list-style: none;
          padding-left: 0;
        }

        h4 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        li {
          margin-left: 21px;
          line-height: 1.4;
          font-weight: 300;
        }
      }
    }
  }
`;

export const InformationsContainer = styled(EmissionContainer)``;

export const EighthTip = styled(SeventhTip)`
  ul {
    padding: 0;
    margin-top: 21px;
    margin-left: 76px;
    font-size: 20px;
    color: #2C3A39;

    @media (max-width: 576px) {
      margin-right: 76px;
    }

    h4 {
      font-weight: 100;
      margin-top: 0;
      margin-bottom: 5px;
    }

    li {
      margin-left: 18px;
      font-weight: 300;
    }
  }
`;

export const EighthTipTitle = styled(SeventhTipTitle)``;

export const NinthTip = styled(EighthTip)``;

export const NinthTipTitle = styled(EighthTipTitle)``;

export const TenthTip = styled(NinthTip)``;

export const TenthTipTitle = styled(NinthTipTitle)``;
