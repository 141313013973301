import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
`;

export const Title = styled.h2`
  margin-top: 53px;
  margin-bottom: 30px;
  text-align: center;
  color: #2C3A39;
  font-size: 40px;
`;

export const SubTitle = styled.h3`
  max-width: 98%;
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  line-height: 1.5;
  color: #2C3A39;
  margin-top: 0;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
`;

export const CollectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px) {
    flex-direction: column;
  }
`;

export const Collection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #1a1446;
  border-radius: 5px;
  padding: 17px 0;

  &:nth-child(1),
  &:nth-child(3) {
    flex: 0.2;

    @media(max-width: 576px) {
      width: 100%;
    }
  }

  &:nth-child(5) {
    flex: 0.6;

    @media(max-width: 576px) {
      width: 100%;
    }
  }

  h4 {
    font-size: 20px;
    color: #2C3A39;
    margin-top: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 20px;
    text-align: center;
    color: #2C3A39;
    margin: 0;

    @media(max-width: 576px) {
      max-width: 320px;
    }

    img {
      height: 11px;
      margin-left: 2px;
      margin-right: 3px;
    }

    span {
      color: #2C3A39;
    }
  }
`;

export const ArrowRight = styled.div`
  margin: 0 20px;

  @media(max-width: 576px) {
    margin: 20px 0;
  }

  img {
    @media(max-width: 576px) {
      transform: rotate(90deg);
    }
  }
`;

export const UseCalculator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #06748c;
  border-radius: 5px;
  margin-top: 15px;
  padding-top: 25px;
  padding-bottom: 30px;

  h4 {
    font-size: 24px;
    text-align: center;
    color: #2C3A39;
    margin-top: 0;
    margin-bottom: 15px;

    @media(max-width: 576px) {
      max-width: 320px;
    }
  }

  button {
    width: 234px;
    font-size: 15px;
    background-color: #FFC900;
    color: #2C3A39;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: ${shade(0.1, '#FFC900')};
    }
  }
`;
