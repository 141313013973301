import React from 'react';

import Car from './assets/carro_celular.svg';
import Phones from './assets/phones.svg';
import Couple from './assets/couple.svg';
import Logo from './assets/shield-yelum.svg';

import {
  Wrapper,
  TechnologyContent,
  AutoContent,
  CompanyContent,
  ClientProfileContent,
  ContentItem,
} from './styles';
import { LINK_APPLE_STORE, LINK_GOOGLE_PLAY } from '~/utils/helpers';

export default function AutoControl({ type }) {
  return (
    <Wrapper>
      {type === 'auto' && (
        <AutoContent id='about-auto-controle-visible'>
          <ContentItem className='auto__content'>
            <p>
              O seguro <strong>AUTO Controle</strong> é o
              <strong> seguro por km da Yelum Seguradora</strong>, em que o
              cliente paga mensalmente, apenas pelo quanto dirige, e com um
              valor até 50% mais econômico que o seguro Auto Perfil.
            </p>
            <p>
              Um produto que{' '}
              <strong>possui todas coberturas de um seguro tradicional,</strong>{' '}
              mas por um preço personalizado de acordo com a quilometragem
              percorrida pelo condutor.
            </p>
          </ContentItem>
          <img src={Car} alt='Carro Smartphone' />
        </AutoContent>
      )}
      {type === 'eligibility' && (
        <TechnologyContent id='about-tecnologia-visible'>
          <ContentItem className='technology__content'>
            <p>
              <strong>
                Todos clientes AUTO Controle deverão instalar um
                aplicativo
              </strong>{' '}
              para tirar mensalmente a foto de odômetro. Esta foto servirá para
              o cálculo da quilometragem rodada e do valor da renovação da
              apólice mensal.
            </p>

            <p>
              <strong>
                O aplicativo AUTO Controle está disponível nas
                principais lojas de aplicativos:
              </strong>
            </p>

            <div>
              -{' '}
              <a href={LINK_APPLE_STORE} target='blank' alt='Apple Store'>
                Apple Store
              </a>
            </div>
            <div>
              -{' '}
              <a href={LINK_GOOGLE_PLAY} target='blank' alt='Google Play'>
                Google Play
              </a>
            </div>
          </ContentItem>          
        </TechnologyContent>
      )}
      {type === 'clientProfile' && (
        <ClientProfileContent id='about-perfil-visible'>
          <ContentItem className='clientProfile__content'>
            <p>
              O AUTO Controle é a opção perfeita para quem quer um preço justo e
              possui aceitação para todos os tipos de veículos!
            </p>

            <ul>
              <li>Aceitação para todos os veículos de passeio;</li>
              <li>
                Motorista de baixa quilometragem, em média 500km mês ou 17km por
                dia;
              </li>
              <li>
                Quem possui mais de um carro na garagem, e faz pouco uso de um
                deles;
              </li>
              <li>
                Prefere utilizar transporte compartilhado no seu dia-a-dia;
              </li>
              <li>Possui um veículo e percorre trajetos curtos.</li>
            </ul>

            <p>
              A economia pode chegar a 50% nos meses em que não dirigir!
              <br />
              Importante: essa oferta será está disponível{' '}
              <strong>
                apenas para moradores da região metropolitana de São Paulo.
              </strong>
            </p>
          </ContentItem>
          <img src={Couple} alt='Casal' />
        </ClientProfileContent>
      )}
      {type === 'liberty' && (
        <CompanyContent id='about-liberty-visible'>
          <ContentItem className='liberty__content'>
            <p>
              A{' '}
              <strong>
                Yelum Seguradora está entre os maiores grupos seguradores do país
              </strong>
              , e conquistamos o mercado brasileiro pelo nosso constante foco no{' '}
              <strong>atendimento excepcional</strong> e{' '}
              <strong>
                por oferecer soluções de seguros que atendam às necessidades dos
                nossos clientes.
              </strong>
            </p>            
          </ContentItem>
          <img src={Logo} height={"420px"}/>
        </CompanyContent>
      )}
    </Wrapper>
  );
}
