import React, { useState } from 'react';
import useWindowSize from '@rooks/use-window-size';

import Modal from '~/components/Modal';
import { Wrapper } from '~/components/_helpers';

import cellPhones from './assets/cellPhones.png';

import * as S from './styles';

export default function Steps({ code }) {
  const size = useWindowSize();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [steps, setSteps] = useState([
    {
      title: 'Contratação',
      description:
        'Fale com o seu corretor e contrate o seu seguro AUTO Controle',
      selected: true,
      id: 'passo-contratacao',
    },
    {
      title: 'Pagamento',
      description:
        'Escolha a forma de pagamento via cartão de crédito ou débito em conta',
      selected: false,
      id: 'passo-pagamento',
    },
    {
      title: 'Vistoria',
      description:
        'A vistoria é realizada em até 7 dias úteis após o envio da proposta',
      selected: false,
      id: 'passo-vistoria',
    },
    {
      title: 'Instalação do aplicativo',
      description:
        'O cliente deve instalar o aplicativo AUTO Controle no celular e criar sua conta (em até 15 dias após o início da vigência)',
      selected: false,
      id: 'passo-instalacao',
    },
    {
      title: 'Tirar a foto do odômetro todos os meses',
      description:
        'Todo mês o cliente deverá tirar a foto do odômetro ou justificar em caso de impossibilidade, tudo através do app.',
      selected: false,
      id: 'passo-foto',
    },
    {
      title: 'Renovação automática',
      description:
        'A renovação é feita automaticamente no final de cada apólice',
      selected: false,
      id: 'passo-renovacao',
    },
  ]);
  return (
    <>
      <S.WrapperSteps>
        <Wrapper
          color='#f5f5f5'
          mobile_mt='0'
          code={code}
          width={size.innerWidth}
        >
          <S.Title>Eu quero! Quais são os próximos passos?</S.Title>
          <S.Container>
            <hr />
            {steps.map((step, i) => (
              <S.Content
                key={`step-${i}`}
                selected={step.selected}
                id={step.id}
              >
                <span
                  onClick={() => {
                    const temp = [];
                    steps.forEach(el => {
                      temp.push({
                        ...el,
                        selected: el.title === step.title,
                      });
                    });
                    setSteps(temp);
                  }}
                >
                  {i + 1}
                </span>
                <p>{step.title}</p>
                {step.selected && <div>{step.description}</div>}
              </S.Content>
            ))}
          </S.Container>
        </Wrapper>
      </S.WrapperSteps>
      {modalIsOpen && (
        <Modal
          width='90%'
          isOpen={modalIsOpen}
          toggle={() => setModalIsOpen(!modalIsOpen)}
          title='Dispositivos de captura de quilometragem'
          paddingHeader='1.125rem 4.25rem 1.125rem 2.125rem'
          className='modal-page-sharing'
        >
          <S.ModalContentContainer>
            <S.ModalContentText>
              <p>
                Todos os clientes AUTO Controle devem instalar o
                dispositivo que captura a quilometragem para o cálculo da
                apólice mensal.
                <strong>
                  As informações de instalação do dispositivo e download do
                  aplicativo serão enviadas por e-mail
                </strong>{' '}
                para cada cliente.
              </p>

              <p>
                Há dois tipos de dispositivo. De acordo com as regras do
                produto, você poderá ser direcionado para um dos dispositivos
                abaixo.
              </p>

              <p>
                <strong>- Dispositivo Ituran</strong>: Indicado para os clientes
                que possuem elegibilidade de um dispositivo antifurto. Trata-se
                da tecnologia GPRS e requer agendamento para instalação,
                realizada por um técnico (em casa ou em local oficial).
              </p>

              <p>
                <strong>- Dispositivo Vai</strong>: Indicado para todos os
                clientes que não são elegíveis ao dispositivo antifurto.
                Trata-se da tecnologia OBDII, simples e segura. O equipamento é
                enviado para o endereço do segurado, que deve realizar a
                instalação.
              </p>
            </S.ModalContentText>
            <S.ModalContentImage>
              <img
                src={cellPhones}
                alt='Imagem de dois celulares exibindo o site Yelum Seguradora Auto Controle'
              />
            </S.ModalContentImage>
          </S.ModalContentContainer>
        </Modal>
      )}
    </>
  );
}
