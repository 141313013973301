import React from 'react';
import PropTypes from 'prop-types';

import { ContentInput } from './styles';

const Input = React.forwardRef(
  ({ icon, textAlign, error, label, iconRight, ...props }, ref) => {
    return (
      <ContentInput hasError={error} textAlign={textAlign}>
        {label}
        <div>
          {icon && !iconRight && <span>{icon}</span>}
          <input ref={ref} {...props} />
          {icon && iconRight && <span>{icon}</span>}
        </div>
        {error && <span>{error}</span>}
      </ContentInput>
    );
  }
);

Input.defaultProps = {
  label: '',
  error: '',
  className: '',
  textAlign: '',
  iconRight: false,
};

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  textAlign: PropTypes.string,
  iconRight: PropTypes.bool,
};

export default Input;
