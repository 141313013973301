import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WrapperComponent = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  padding: 0 0.25rem;
  background-color: ${props => (props.color ? props.color : '#fff')};
  overflow: hidden;
  margin-top: 0;

  &:nth-child(1) {
    padding-bottom: ${({ code, values }) =>
      !code && !values && 'calc(100vh - 847.62px)'};
  }

  @media (max-width: 768px) {
    margin-top: ${props => (props.mobile_mt ? props.mobile_mt : '91px')};
    justify-content: space-between;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: block;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 10px;

    /*max-width: ${({ width }) => width - 10}px;*/
  }

  @media (max-width: 768px) {
    max-width: ${({ width }) => width - 10}px;
  }

  @media (max-width: 576px) {
    max-width: ${({ width }) => width - 7}px;
  }
`;

const Wrapper = ({
  children,
  color,
  id,
  faq,
  mobile_mt,
  height,
  code,
  values,
  width,
}) => (
  <WrapperComponent
    height={height}
    color={color}
    id={id}
    faq={faq}
    mobile_mt={mobile_mt}
    code={code}
    values={values}
    id='wrapper'
  >
    <Container width={width}>{children}</Container>
  </WrapperComponent>
);

export default Wrapper;

Wrapper.defaultProps = {
  color: '',
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};
