const Types = {
  STATUS_MIDDLEWARE_LOADER: '@loader/STATUS_MIDDLEWARE_LOADER',
  STATUS_LOADER: '@loader/STATUS_LOADER',
};

const initialState = {
  middleware: false,
  loader: {
    children: true,
    loading: false,
    error: false,
    refused: false,
  },
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.STATUS_MIDDLEWARE_LOADER: {
      const { middleware } = action.payload;
      return { ...state, middleware };
    }

    case Types.STATUS_LOADER: {
      const { loader } = action.payload;
      return { ...state, loader };
    }

    default:
      return { ...state };
  }
};

export const handleSetMiddlewareLoader = middleware => ({
  type: Types.STATUS_MIDDLEWARE_LOADER,
  payload: { middleware },
});

export const handleSetStatusLoader = loader => ({
  type: Types.STATUS_LOADER,
  payload: { loader },
});
