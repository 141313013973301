import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: ${({ brokerInUrl }) => brokerInUrl && '100vh'};
  position: relative;
  display: grid;
  grid-template-areas: 'header' 'content' 'footer';
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

export const Content = styled.div`
  grid-area: content;
  ${({ brokerInUrl, codeInUrl }) => css`
    height: ${brokerInUrl && 'calc(100% - 23.375rem)'};
    position: relative;
    margin-top: ${() => {
      if (brokerInUrl) return '9.6875rem';
      if (codeInUrl) return '0';
      return window.innerWidth >= 576 ? '0' : '13.125rem';
    }};
  `};
`;
