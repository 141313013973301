import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleSetStatusLoader } from '~/store/modules/loader';
import Button from '~/components/_helpers/Button';
import unplugged from './assets/unplugged.svg';
import { Container, Content } from './styles';

export default function Error() {
  const dispatch = useDispatch();
  const { loader } = useSelector(state => state.loaderReducer);

  return (
    <Container>
      <Content>
        <img src={unplugged} alt="Problems" />
      </Content>
      <Content>
        <h1>Ops! Algo deu errado</h1>
        <p>
          Ocorreu um erro durante o processamento da sua requisição, nossos
          administradores foram informados do ocorrido.
        </p>
        <Button
          theme="primary"
          onClick={() => {
            if (loader.retry) {
              loader.retry();
            }
            dispatch(
              handleSetStatusLoader({
                children: true,
                loading: false,
                error: false,
                refused: false,
              })
            );
          }}
        >
          Tentar novamente
        </Button>
      </Content>
    </Container>
  );
}
