import React from 'react';
import LogoAutoControle from './assets/logo-autocontrole.svg';
import { ContentHeader } from './styles';

export default function LoginHeader() {
  return (
    <ContentHeader>
      <img src={LogoAutoControle} height="90px" />
    </ContentHeader>
  );
}
