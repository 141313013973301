const Types = {
    SET_CODE: '@code/SET_CODE',
  };
  
  const initialState = {
    code: null,
  };
  
  export const codeReducer = (state = initialState, action) => {
    switch (action.type) {
      case Types.SET_CODE: {
        const { code } = action.payload;
        return { ...state, code };
      }
  
      default:
        return { ...state };
    }
  };
  
  export const handleSetCode = code => ({
    type: Types.SET_CODE,
    payload: { code },
  });
  